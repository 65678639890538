<template>
    <BasePage>
        <div class="container">
            <!--<h1>Welcome to the Home Page</h1> -->
            <div class="row row-deck gap-3">
                <div class="card col col-12 col-md-3">
                    <div class="card-header">
                        <h2 class="card-title">Orders</h2>
                    </div>
                    <div v-if="!loaded" class="card-body text-end placeholder-glow">
                        <div class="placeholder col-9 mb-3"></div>
                        <div class="placeholder placeholder-xs col-10"></div>
                        <div class="placeholder placeholder-xs col-12"></div>
                        <div class="placeholder placeholder-xs col-11"></div>
                        <div class="placeholder placeholder-xs col-8"></div>
                        <div class="placeholder placeholder-xs col-10"></div>
                    </div>
                    <div v-if="loaded" class="card-body">
                        <div class="subheader mt-2">Ordered</div>
                        {{ checkouts.paid }}
                        <div class="subheader mt-2">Abandoned</div>
                        {{ checkouts.pending }}
                    </div>
                </div>
                <div class="card col-12 col-md-3">
                    <div class="card-header">
                        <h2 class="card-title">Baskets</h2>
                    </div>
                    <div v-if="!loaded" class="card-body text-end placeholder-glow">
                        <div class="placeholder col-9 mb-3"></div>
                        <div class="placeholder placeholder-xs col-10"></div>
                        <div class="placeholder placeholder-xs col-12"></div>
                        <div class="placeholder placeholder-xs col-11"></div>
                        <div class="placeholder placeholder-xs col-8"></div>
                        <div class="placeholder placeholder-xs col-10"></div>
                    </div>
                    <div v-if="loaded" class="card-body">
                        <div class="subheader mt-2">Total Products Added</div>
                        {{ parseInt(basket.active) + parseInt(basket.removed) }}
                        <div class="subheader mt-2">Active Products</div>
                        {{ basket.active }}
                        <div class="subheader mt-2">Removed Products</div>
                        {{ basket.removed }}
                    </div>
                </div>
                <div class="card col">
                    <div class="card-header">
                        <h2 class="card-title">Popular Products</h2>
                    </div>
                    <div v-if="!loaded" class="card-body text-end placeholder-glow">
                        <div class="placeholder col-9 mb-3"></div>
                        <div class="placeholder placeholder-xs col-10"></div>
                        <div class="placeholder placeholder-xs col-12"></div>
                        <div class="placeholder placeholder-xs col-11"></div>
                        <div class="placeholder placeholder-xs col-8"></div>
                        <div class="placeholder placeholder-xs col-10"></div>
                    </div>
                    <div v-if="loaded" class="card-body">
                        <table class="table-bordered table">
                            <thead>
                            <th>Item</th>
                            <th>Ordered</th>
                            </thead>
                            <tr v-for="i in orderedItems" :key="i">
                                <td>{{ i.name }}</td>
                                <td>{{ i.total }}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <div class="row mt-6 row-deck gap-3">
                <h2 class="h2 mb-3">Analytics for last 7 Days</h2>
                <div class="col card">
                    <div v-if="!loaded" class="card-body text-end placeholder-glow">
                        <div class="placeholder col-9 mb-3"></div>
                        <div class="placeholder placeholder-xs col-10"></div>
                        <div class="placeholder placeholder-xs col-12"></div>
                        <div class="placeholder placeholder-xs col-11"></div>
                        <div class="placeholder placeholder-xs col-8"></div>
                        <div class="placeholder placeholder-xs col-10"></div>
                    </div>
                    <div v-if="loaded" class="card-body">
                        <h2 class="card-title">Browsers</h2>
                        <table class="table table-sm table-borderless">
                            <thead>
                            <th>Browser</th>
                            <th>Count</th>
                            </thead>
                            <tbody>
                            <tr v-for="browser in devices.browsers" :key="browser.name">
                                <td>
                                    <div class="progressbg">
                                        <div class="progress progressbg-progress">
                                            <div :style="{ width: browser.percent + '%' }" class="progress-bar progress-bar-animated bg-primary"></div>
                                        </div>
                                        <div class="progressbg-text">{{browser.name}}</div>
                                    </div>
                                </td>
                                <td>
                                    {{browser.count}}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="col card">
                    <div v-if="!loaded" class="card-body text-end placeholder-glow">
                        <div class="placeholder col-9 mb-3"></div>
                        <div class="placeholder placeholder-xs col-10"></div>
                        <div class="placeholder placeholder-xs col-12"></div>
                        <div class="placeholder placeholder-xs col-11"></div>
                        <div class="placeholder placeholder-xs col-8"></div>
                        <div class="placeholder placeholder-xs col-10"></div>
                    </div>
                    <div v-if="loaded" class="card-body">
                        <h2 class="card-title">Operating Systems</h2>
                        <table class="table table-sm table-borderless">
                            <thead>
                            <th>OS</th>
                            <th>Count</th>
                            </thead>
                            <tbody>
                            <tr v-for="os in devices.os" :key="os.name">
                                <td>
                                    <div class="progressbg">
                                        <div class="progress progressbg-progress">
                                            <div :style="{ width: os.percent + '%' }" class="progress-bar progress-bar-animated bg-primary"></div>
                                        </div>
                                        <div class="progressbg-text">{{os.name}}</div>
                                    </div>
                                </td>
                                <td>
                                    {{os.count}}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="col card">
                    <div v-if="!loaded" class="card-body text-end placeholder-glow">
                        <div class="placeholder col-9 mb-3"></div>
                        <div class="placeholder placeholder-xs col-10"></div>
                        <div class="placeholder placeholder-xs col-12"></div>
                        <div class="placeholder placeholder-xs col-11"></div>
                        <div class="placeholder placeholder-xs col-8"></div>
                        <div class="placeholder placeholder-xs col-10"></div>
                    </div>
                    <div v-if="loaded" class="card-body">
                        <h2 class="card-title">Device Types</h2>
                        <table class="table table-sm table-borderless">
                            <thead>
                            <th>Device</th>
                            <th>Count</th>
                            </thead>
                            <tbody>
                            <tr v-for="browser in devices.type" :key="browser.name">
                                <td>
                                    <div class="progressbg">
                                        <div class="progress progressbg-progress">
                                            <div :style="{ width: browser.percent + '%' }" class="progress-bar progress-bar-animated bg-primary"></div>
                                        </div>
                                        <div class="progressbg-text">{{browser.name}}</div>
                                    </div>
                                </td>
                                <td>
                                    {{browser.count}}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="row row-deck gap-3 mt-6">
                <div class="col card">
                    <div v-if="!loaded" class="card-body text-end placeholder-glow">
                        <div class="placeholder col-9 mb-3"></div>
                        <div class="placeholder placeholder-xs col-10"></div>
                        <div class="placeholder placeholder-xs col-12"></div>
                        <div class="placeholder placeholder-xs col-11"></div>
                        <div class="placeholder placeholder-xs col-8"></div>
                        <div class="placeholder placeholder-xs col-10"></div>
                    </div>
                    <div v-if="loaded" class="card-body">
                        <h2 class="card-title">Page Views</h2>
                        <table class="table table-sm table-borderless">
                            <thead>
                            <th>Device</th>
                            <th>Count</th>
                            </thead>
                            <tbody>
                            <tr v-for="path in paths" :key="path.dimensions.requestPath">
                                <td>
                                    <div class="progressbg">
                                        <div class="progress progressbg-progress">
                                            <div :style="{ width: path.percent + '%' }" class="progress-bar progress-bar-animated bg-primary"></div>
                                        </div>
                                        <div class="progressbg-text">{{path.dimensions.requestPath}}</div>
                                    </div>
                                </td>
                                <td>
                                    {{path.count}}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="col card">
                    <div v-if="!loaded" class="card-body text-end placeholder-glow">
                        <div class="placeholder col-9 mb-3"></div>
                        <div class="placeholder placeholder-xs col-10"></div>
                        <div class="placeholder placeholder-xs col-12"></div>
                        <div class="placeholder placeholder-xs col-11"></div>
                        <div class="placeholder placeholder-xs col-8"></div>
                        <div class="placeholder placeholder-xs col-10"></div>
                    </div>
                    <div v-if="loaded" class="card-body">
                        <h2 class="card-title">Traffic Sources</h2>
                        <table class="table table-sm table-borderless">
                            <thead>
                            <th>Referer</th>
                            <th>Count</th>
                            </thead>
                            <tbody>
                            <tr v-for="ref in referrals" :key="ref.name">
                                <td>
                                    <div class="progressbg">
                                        <div class="progress progressbg-progress">
                                            <div :style="{ width: ref.percent + '%' }" class="progress-bar progress-bar-animated bg-primary"></div>
                                        </div>
                                        <div class="progressbg-text">{{ref.name}}</div>
                                    </div>
                                </td>
                                <td>
                                    {{ref.count}}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="col card d-none">
                    <div class="card-header">
                        <h2 class="card-title">Analytics</h2>
                    </div>
                    <div v-if="!loaded" class="card-body text-end placeholder-glow">
                        <div class="placeholder col-9 mb-3"></div>
                        <div class="placeholder placeholder-xs col-10"></div>
                        <div class="placeholder placeholder-xs col-12"></div>
                        <div class="placeholder placeholder-xs col-11"></div>
                        <div class="placeholder placeholder-xs col-8"></div>
                        <div class="placeholder placeholder-xs col-10"></div>
                    </div>
                    <div v-if="loaded" class="card-body">
                        <table class="table table-bordered">
                            <thead>
                            <th>Date</th>
                            <th>Request</th>
                            <th>Unique Visitors</th>
                            </thead>
                            <tr v-for="day in analytics" :key="day">
                                <td>{{ day.dimensions.date }}</td>
                                <td>{{ day.sum.pageViews }}</td>
                                <td>{{ day.uniq.uniques }}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </BasePage>
</template>

<script>
document.title = "Home | CarneCloud"
import api from '@/services/api'
import BasePage from '@/components/BasePage.vue';  // Adjust the import path if necessary

export default {
    name: 'HomePage',
    components: {
        BasePage,
    },
    data() {
        return {
            checkouts: {},
            basket: {},
            orderedItems: [],
            analytics: [],
            paths: [],
            devices: {},
            referrals: [],
            loaded: false
        }
    },
    async mounted() {
        const res = await api.get('/api/homepage')
        this.checkouts = res.data.checkouts[0]
        this.basket = res.data.basket[0]
        this.orderedItems = res.data.orderedItems.sort((a, b) => b.total - a.total).slice(0, 10);
        this.analytics = res.data.analytics
        this.devices = res.data.devices
        this.paths = res.data.paths
        this.referrals = res.data.referrals
        this.loaded = true;
    }
};
</script>
