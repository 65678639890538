<template>
    <div class="card-header">
        <h2 class="card-title">Products Overview</h2>
    </div>
    <!--<div class="card-body">
        <div class="datagrid">
            <div v-for="product in products" :key="product" class="datagrid-item">
                <div class="datagrid-title">
                    {{product.sku}}
                </div>
                <div class="datagrid-content">
                    {{product.qty}}
                </div>
            </div>
        </div>
    </div> -->
    <div class="card-body">
        <h2 class="subheader">Quantities Ordered</h2>
        <div v-for="item in orderedProducts" :key="item.typeId">
            <div class="card mb-4" v-if="item.products">
                <div class="card-header bg-body-tertiary">
                    <h3 class="card-title">
                        {{item.typeName}}
                    </h3>
                </div>
                <div v-for="product in item.products" :key="product" class="card-body">
                    <div class="card">
                        <div class="card-header bg-blue-lt text-primary-fg">
                            <h3 class="card-title text-facebook">
                                {{product.product}}
                            </h3>
                            <div class="card-options">
                                <a target="_blank" :href="'/label/' + product.sku" class="btn btn-sm btn-ghost-primary"><IconTag></IconTag></a>
                            </div>
                            <div v-if="product.unit === 'each'" class="card-options">
                                {{product.total}}
                            </div>
                        </div>
                        <div v-if="product.unit === 'kg'" class="card-body">
                            <div class="datagrid">
                                <div class="datagrid-item" v-for="size in product.sizes" :key="size.size">
                                    <div class="datagrid-title">
                                        Size: {{size.size}} {{product.unit}}
                                    </div>
                                    <div class="datagrid-content">
                                        Count: {{ size.count }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {IconTag} from '@tabler/icons-vue'
export default {
    props: {
        products: {
            type: Array,
        },
        orderedProducts: {
            type: Array
        }
    },
    components: {
        IconTag
    }
}
</script>