<template>
    <div class="card mt-6">
        <div class="card-header">
            <h3 class="card-title">Customisation Options</h3>
            <div class="card-options">
                <button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addCustomisationOptionModal">Add
                    Customisation Option
                </button>
            </div>
        </div>
        <div class="card-body">
            <div
                    v-for="(customisation) in customisations"
                    :key="customisation.customisationId"
                    class="accordion mt-3"
                    :id="'accordion-' + customisation.customisationId"
            >
                <div class="accordion-item">
                    <h2 class="accordion-header"
                        :id="'heading-' + customisation.customisationId">
                        <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                :data-bs-target="'#collapse-' + customisation.customisationId"
                                aria-expanded="true"
                                :aria-controls="'collapse-' + customisation.customisationId"
                        >
                            {{ customisation.customisationName }}
                        </button>
                    </h2>
                    <div
                            :id="'collapse-' + customisation.customisationId"
                            class="accordion-collapse collapse"
                            aria-labelledby="'heading-' + customisation.customisationId"
                            :data-bs-parent="'#accordion-' + customisation.customisationId"
                    >
                        <div class="accordion-body">
                            {{ customisation.customisationText }}
                            <table class="table table-striped table-hover mt-4">
                                <thead>
                                <tr>
                                    <th>Option Name</th>
                                    <th>Option Value</th>
                                    <th>Default</th>
                                    <th class="w-1 text-end text-nowrap">
                                        <div class="btn btn-sm btn-primary" data-bs-toggle="modal"
                                             @click="setUpOptionInput(customisation)"
                                             data-bs-target="#addCustomisationOptionItemModal">
                                            <IconPlus></IconPlus>
                                        </div>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="option in customisation.customisationOptions" :key="option.optionID">
                                    <td>{{ option.customisationOptionName }}</td>
                                    <td>{{ option.customisationValue }}</td>
                                    <td>
                                        <label class="form-check form-switch">
                                            <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    v-model="option.customisationDefault"
                                                    :true-value="1"
                                                    :false-value="0"
                                                    @change="setDefault(option)"
                                            >
                                            <span class="form-check-label">Default</span>
                                        </label>
                                    </td>
                                    <td>
                                        <div class="d-flex">
                                            <!--<div @click="moveUp(option)" class="btn btn-sm btn-ghost-success me-1">
                                                <IconArrowBigUpFilled></IconArrowBigUpFilled>
                                            </div>
                                            <div @click="moveDown(option)" class="btn btn-sm btn-ghost-success me-2">
                                                <IconArrowBigDownFilled></IconArrowBigDownFilled>
                                            </div> -->
                                            <div @click="setDeleteMessage(option)" class="btn-ghost-danger btn btn-sm"
                                                 data-bs-toggle="modal" data-bs-target="#deleteOptionModal">
                                                <IconTrash></IconTrash>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            <div @click="setDeleteMessage(customisation)" class="ml-4 btn-ghost-danger btn btn-sm"
                                 data-bs-toggle="modal" data-bs-target="#deleteOptionModal">
                                <IconTrash></IconTrash>
                                Delete Customisation
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal modal-blur fade" ref="addCustomisationModal" id="addCustomisationOptionModal" tabindex="-1"
         role="dialog" aria-modal="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Add Customisation</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <form @submit.prevent="handleCustomisationSubmit">
                    <div class="modal-body">
                        <div v-if="errorMessage" class="alert alert-danger" role="alert">
                            {{ errorMessage }}
                        </div>
                        <div class="mb-3">
                            <label for="customisationName" class="form-label">Customisation Name</label>
                            <input
                                    type="text"
                                    class="form-control"
                                    id="customisationName"
                                    v-model="customisationFormData.customisationName"
                                    required
                            />
                        </div>
                        <div class="mb-3">
                            <label for="customisationText" class="form-label">Customisation Text</label>
                            <input
                                    type="text"
                                    class="form-control"
                                    id="customisationText"
                                    v-model="customisationFormData.customisationText"
                                    required
                            />
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" ref="customisationClose" class="btn me-auto" data-bs-dismiss="modal">
                            Cancel
                        </button>
                        <button type="submit" class="btn btn-primary">Add</button>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="modal modal-blur fade" id="addCustomisationOptionItemModal" tabindex="-1" role="dialog"
         aria-modal="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Add Customisation Option</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <form @submit.prevent="handleOptionSubmit">
                    <div class="modal-body">
                        <div v-if="errorMessage" class="alert alert-danger" role="alert">
                            {{ errorMessage }}
                        </div>

                        <div class="mb-3">
                            <label for="customisationOptionName" class="form-label">Customisation Option Name</label>
                            <input
                                    type="text"
                                    class="form-control"
                                    id="customisationOptionName"
                                    v-model="optionFormData.customisationOptionName"
                                    required
                            />
                        </div>
                        <div class="mb-3">
                            <label for="customisationValue" class="form-label">Customisation Value</label>
                            <input
                                    type="text"
                                    class="form-control"
                                    id="customisationValue"
                                    v-model="optionFormData.customisationValue"
                                    required
                            />
                        </div>
                        <div class="mb-3">
                            <label for="customisationDefault" class="form-label">Default</label>
                            <input
                                    type="checkbox"
                                    class="form-check-input"
                                    id="customisationDefault"
                                    v-model="optionFormData.customisationDefault"
                                    :disabled="customisationDefaultLocked"
                            />
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn me-auto" ref="closeOptionButton" data-bs-dismiss="modal">
                            Cancel
                        </button>
                        <button type="submit" class="btn btn-primary">Add</button>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <DangerModal
            ref="modalDeleteOption"
            modalId="deleteOptionModal"
            title="Delete Option"
            :message="deleteMessage"
            closeButton="Cancel"
            submitButton="Delete"
            @submit="handleOptionDelete"
            :errorMessage="deleteErrorMessage"
    ></DangerModal>
</template>
<script>
import api from "@/services/api";
import {IconTrash, IconPlus, /*IconArrowBigUpFilled, IconArrowBigDownFilled*/} from "@tabler/icons-vue"
import DangerModal from "@/components/DangerModal.vue"

export default {
    name: "CustomisationOptions",
    data() {
        return {
            customisations: {},
            customisationFormData: {
                customisationName: '',
                customisationText: '',
                productId: this.$route.params.productId,
            },
            optionFormData: {
                customisationValue: '',
                customisationOptionName: '',
                customisationId: null,
                customisationDefault: false,
            },
            customisationDefaultLocked: false,
            errorMessage: '',
            deleteMessage: '',
            deleteErrorMessage: '',
            optionToDelete: null,
            optionCustomisationToDelete: null
        }
    },
    async mounted() {
        await this.getCustomisations();
    },
    methods: {
        moveUp(option) {
            console.log(option)
            // Implement logic to increase the order of the selected option
            // and decrease the order of the option above it, then update the database.
        },
        moveDown(option) {
            console.log(option)
            // Implement logic to decrease the order of the selected option
            // and increase the order of the option below it, then update the database.
        },
        async getCustomisations() {
            try {
                this.customisations = (await api.get("/api/customisations/product/" + this.$route.params.productId)).data
            } catch (e) {
                console.log(e.message)
            }
        },
        setUpOptionInput(customisation) {
            this.optionFormData.customisationId = customisation.customisationId;
            if (customisation.customisationOptions.length === 0) {
                this.optionFormData.customisationDefault = true;
                this.customisationDefaultLocked = true;
            } else {
                this.optionFormData.customisationDefault = false;
                this.customisationDefaultLocked = false;
            }
        },
        async updateDefault(customisationId) {
            // Assume customisationOptionsData is the data structure holding your customisation options
            //const customisationOptions = this.customisations[customisationId]?.customisationOptions;
            const customisationOptions = this.customisations.find(c => c.customisationId === customisationId).customisationOptions
            if (!customisationOptions) {
                alert("No defaults")
                //this.errorMessage = 'No customisation options found for the specified customisation ID.';
                return;
            }

            // Find any default options
            const defaultOptions = customisationOptions.filter(option => option.customisationDefault === 1);

            // Update each default option to not default
            const updatePromises = defaultOptions.map(option => {
                const updatedOptionData = {...option, customisationDefault: 0};
                return api.put(`/api/customisationOption/${option.optionID}`, updatedOptionData);
            });

            // Wait for all update requests to complete
            Promise.all(updatePromises)
                // eslint-disable-next-line
                .then(responses => {
                    // Handle successful updates, e.g., refresh data, update the UI, etc.
                })
                // eslint-disable-next-line
                .catch(error => {
                    // Handle error during updates
                    // this.errorMessage = error.message || 'An error occurred while updating the default customisation options.';
                });
        },
        handleCustomisationSubmit() {
            if (this.customisationFormData.customisationName && this.customisationFormData.customisationText && this.customisationFormData.productId) {
                // Prepare the data for submission
                const customisationData = {
                    customisationName: this.customisationFormData.customisationName,
                    customisationText: this.customisationFormData.customisationText,
                    productId: this.$route.params.productId,
                };

                // Call submitCustomisation to handle the actual data submission
                this.submitCustomisation(customisationData)
                    // eslint-disable-next-line
                    .then(response => {
                        this.customisationFormData.customisationName = '';
                        this.customisationFormData.customisationText = '';
                        this.customisationFormData.productId = this.$route.params.productId;
                        this.errorMessage = '';
                        this.$refs.customisationClose.click()
                        this.getCustomisations();
                    })
                    .catch(error => {
                        // Handle error during submission
                        this.errorMessage = error.message || 'An error occurred while submitting the customisation.';
                    });
            } else {
                // Handle missing required fields
                this.errorMessage = 'All fields are required.';
            }
        },
        submitCustomisation(data) {
            // Use the api.post method to submit the data
            return api.post('/api/customisations', data)
                .then(response => {
                    // Check for any error responses or conditions based on your API setup
                    if (response.data.error) {
                        return Promise.reject(new Error(response.data.error.message));
                    }
                    return response.data;  // Adjust based on your API response structure
                })
                .catch(error => {
                    // Optionally handle any errors at this level, or allow them to propagate to the calling method
                    return Promise.reject(error);
                });
        },
        setDefault(option) {
            this.updateDefault(option.customisationId)
                .then(() => {
                    // Prepare the data for submission
                    //const updatedOptionData = { ...option, customisationDefault:  };

                    // Call api.put to update the specified option to be the new default
                    return api.put(`/api/customisationOption/${option.optionID}`, option);
                })
                // eslint-disable-next-line
                .then(response => {
                    this.getCustomisations()
                    // Handle successful update, e.g., refresh data, update the UI, etc.
                })
                .catch(error => {
                    // Handle error during update
                    console.log(error.message || 'An error occurred while updating the default customisation option.')
                });
        },
        handleOptionDelete() {
            if (this.optionToDelete) {
                // Use the api.delete method to send a DELETE request
                return api.delete(`/api/customisations/${this.optionToDelete}`)
                    // eslint-disable-next-line
                    .then(response => {
                        // Handle successful deletion, e.g., update the UI, clear the optionToDelete, etc.
                        this.optionToDelete = null;
                        this.$refs.modalDeleteOption.$refs.closeButton.click();
                        this.getCustomisations();

                    })
                    .catch(error => {
                        // Handle error during deletion
                        this.deleteErrorMessage = error.message || 'An error occurred while deleting the option.';
                    });
            } else {
                // Handle missing optionToDelete
                this.deleteErrorMessage = 'No option selected for deletion.';
            }
            if (this.optionCustomisationToDelete) {
                // Use the api.delete method to send a DELETE request
                return api.delete(`/api/customisationOption/${this.optionCustomisationToDelete}`)
                    // eslint-disable-next-line
                    .then(response => {
                        // Handle successful deletion, e.g., update the UI, clear the optionToDelete, etc.
                        this.optionToDelete = null;
                        this.$refs.modalDeleteOption.$refs.closeButton.click();
                        this.deleteErrorMessage = ""
                        this.getCustomisations();
                    })
                    .catch(error => {
                        // Handle error during deletion
                        this.errorMessage = error.message || 'An error occurred while deleting the customisation option.';
                    });
            } else {
                // Handle missing optionToDelete
                this.errorMessage = 'No customisation option selected for deletion.';
            }
        },
        async handleOptionSubmit() {
            if (this.optionFormData.customisationValue && this.optionFormData.customisationOptionName && this.optionFormData.customisationId !== null) {
                // Prepare the data for submission
                const customisationOptionData = {
                    customisationValue: this.optionFormData.customisationValue,
                    customisationOptionName: this.optionFormData.customisationOptionName,
                    customisationId: this.optionFormData.customisationId,
                    customisationDefault: this.optionFormData.customisationDefault ? 1 : 0,
                };

                if (this.optionFormData.customisationDefault) {
                    await this.updateDefault(this.optionFormData.customisationId)
                }
                // Call submitCustomisationOption to handle the actual data submission
                this.submitCustomisationOption(customisationOptionData)
                    // eslint-disable-next-line
                    .then(response => {
                        // Handle successful submission, e.g., clear the form, hide the modal, update the UI, etc.
                        this.optionFormData.customisationValue = '';
                        this.optionFormData.customisationOptionName = '';
                        this.optionFormData.customisationId = null;
                        this.optionFormData.customisationDefault = false;
                        this.errorMessage = '';
                        this.$refs.closeOptionButton.click();
                        this.getCustomisations();
                    })
                    .catch(error => {
                        // Handle error during submission
                        this.errorMessage = error.message || 'An error occurred while submitting the customisation option.';
                    });
            } else {
                // Handle missing required fields
                this.errorMessage = 'All fields except Default are required.';
            }
        },

        submitCustomisationOption(data) {
            // Use the api.post method to submit the data
            return api.post('/api/customisationOption', data)
                .then(response => {
                    // Check for any error responses or conditions based on your API setup
                    if (response.data.error) {
                        return Promise.reject(new Error(response.data.error.message));
                    }
                    return response.data;  // Adjust based on your API response structure
                })
                .catch(error => {
                    // Optionally handle any errors at this level, or allow them to propagate to the calling method
                    return Promise.reject(error);
                });
        },
        setDeleteMessage(item) {
            if (item.productId) {
                this.optionToDelete = item.customisationId;
                this.deleteMessage = `Are you sure you want to delete customisation "${item.customisationName}?"`
            }
            if (item.customisationOptionName) {
                this.optionCustomisationToDelete = item.optionID;
                this.deleteMessage = `Are you sure you want to delete customisation option "${item.customisationOptionName}?"`
            }
        }

    },
    components: {
        IconTrash,
        //IconArrowBigUpFilled,
        //IconArrowBigDownFilled,
        IconPlus,
        DangerModal
    }
}
</script>