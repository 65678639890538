/* eslint-disable */
<template>
    <div class="d-flex justify-content-center align-items-center vh-100">
        <div class="card w-50" style="max-width: 400px">
            <div class="card-body">
                <h1 class="text-center mb-6">Log in</h1>
                <div v-if="errorMessage" class="alert alert-danger" role="alert">
                    {{ errorMessage }}
                </div>
                <form @submit.prevent="login">
                    <div class="mb-4">
                        <label class="form-label">Username</label>
                        <input type="text" class="form-control" v-model="username" placeholder="Enter username">
                    </div>
                    <div class="mb-4">
                        <label class="form-label">Password</label>
                        <input type="password" class="form-control" v-model="password" placeholder="Password">
                    </div>
                    <div class="text-center">
                        <button type="submit" class="btn btn-primary">Sign in</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>


<script>
document.title = "Login | CarneCloud"
import api from '@/services/api';
import { useRouter } from 'vue-router';  // Import useRouter

export default {
    data() {
        return {
            username: '',
            password: '',
            errorMessage: '',
        };
    },
    setup() {
        const router = useRouter();  // Setup router instance
        return { router };  // Return router instance for use in methods
    },
    methods: {
        async login() {
            try {
                const response = await api.post('/api/login', {
                    username: this.username,
                    password: this.password,
                });
                localStorage.setItem('token', response.data.token);
                this.errorMessage = '';
                console.log('Login successful:', response.data);
                const redirectPath = this.$route.query.redirect || '/';
                this.$router.push(redirectPath);
            } catch (error) {
                this.errorMessage = 'Incorrect username or password';
                console.error('Login error:', error);
            }
        },
    },
};
</script>