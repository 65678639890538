<template>
    <ul class="navbar-nav">
        <li class="nav-item" :class="{ active: isActive('/') }">
            <a class="nav-link" href="/">
                        <span class="nav-link-icon d-md-none d-lg-inline-block">
                            <IconHome></IconHome>
                        </span>
                <span class="nav-link-title">Home</span>
            </a>
        </li>
        <li class="nav-item" :class="{ active: isActive('/pim') }">
            <a class="nav-link" href="/pim">
                        <span class="nav-link-icon d-md-none d-lg-inline-block">
                            <IconBox></IconBox>
                        </span>
                <span class="nav-link-title">PIM</span>
            </a>
        </li>
        <li class="nav-item" :class="{ active: isActive('/orders') }">
            <a class="nav-link" href="/orders">
                        <span class="nav-link-icon d-md-none d-lg-inline-block">
                            <IconFileAnalytics></IconFileAnalytics>
                        </span>
                <span class="nav-link-title">Orders</span>
            </a>
        </li>
        <li class="nav-item" :class="{ active: isActive('/reports') }">
            <a class="nav-link" href="/reports/collection">
                        <span class="nav-link-icon d-md-none d-lg-inline-block">
                            <IconChartHistogram></IconChartHistogram>
                        </span>
                <span class="nav-link-title">Reports</span>
            </a>
        </li>
        <li class="nav-item" :class="{ active: isActive('/deliveries') }">
            <a class="nav-link" href="/deliveries">
                        <span class="nav-link-icon d-md-none d-lg-inline-block">
                            <IconTruck></IconTruck>
                        </span>
                <span class="nav-link-title">Deliveries</span>
            </a>
        </li>
        <li class="nav-item" :class="{ active: isActive('/configuration') }">
            <a class="nav-link" href="/configuration">
                        <span class="nav-link-icon d-md-none d-lg-inline-block">
                            <IconSettings></IconSettings>
                        </span>
                <span class="nav-link-title">Configuration</span>
            </a>
        </li>
        <li class="nav-item" :class="{ active: isActive('/faq') }">
            <a class="nav-link" href="/faq">
                        <span class="nav-link-icon d-md-none d-lg-inline-block">
                            <IconHelpHexagon></IconHelpHexagon>
                        </span>
                <span class="nav-link-title">FAQ</span>
            </a>
        </li>
        <li class="nav-item" :class="{ active: isActive('/collected') }">
            <a class="nav-link" href="/collected">
                        <span class="nav-link-icon d-md-none d-lg-inline-block">
                            <IconQrcode></IconQrcode>
                        </span>
                <span class="nav-link-title">Scan Slips</span>
            </a>
        </li>


    </ul>
</template>
<script>
import {IconHome,IconQrcode, IconBox, IconFileAnalytics, IconTruck, IconChartHistogram, IconSettings, IconHelpHexagon} from "@tabler/icons-vue";

export default {
    methods: {
        isActive(path) {
            if (path === "/" && this.$route.path.length > 1) {
                return false;
            }
            return this.$route.path.startsWith(path);
        }
    },
    components: {
        IconHome,
        IconBox,
        IconFileAnalytics,
        IconTruck,
        IconChartHistogram,
        IconSettings,
        IconQrcode,
        IconHelpHexagon
    }
}


</script>