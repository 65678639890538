<template>
    <div class="card mt-5">
        <div class="card-header">
            <h3 class="card-title">Orders</h3>
            <div class="card-options gap-4">
                <div class="input-group input-group-flat">
                    <span class="input-group-text"><IconSearch></IconSearch></span>
                    <input class="form-control" placeholder="Search..." v-model="orderSearch" >
                    <span class="input-group-text">
                        <div @click="orderSearch = ''" class="btn btn-sm btn-ghost-secondary"><IconX></IconX></div>
                    </span>
                </div>
            </div>
        </div>
        <div class="card-body">
            <table class="table align-items-center">
                <thead>
                <tr>
                    <th scope="col">Order No</th>
                    <th scope="col">Name</th>
                    <th scope="col">Distribution Option</th>
                </tr>
                </thead>
                <tbody class="table-tbody">
                <tr v-for="order in paginatedItems" :key="order.id">
                    <td>
                        <router-link :to="`/orders/${order.id}`">
                            {{ order.id }}
                        </router-link>
                    </td>
                    <td>{{ order.firstName }} {{order.lastName}}</td>
                    <td>{{order.collectionOption}}</td>
                </tr>
                </tbody>
            </table>
        </div>
        <Pagination
            :totalItems="filteredItems.length"
            :itemsPerPage="20"
            :currentPage="currentPage"
            @page-changed="updateCurrentPage" />
    </div>
</template>
<script>
import {IconSearch, IconX} from "@tabler/icons-vue";
import Pagination from "@/components/PaginationComponent.vue";

export default {
    name: "OrdersOverview",
    components: {Pagination, IconX, IconSearch},
    data() {
        return {
            orderSearch: "",
            currentPage: 1,
        }
    },
    methods: {
        updateCurrentPage(page) {
            this.currentPage = page;
        },
    },
    computed: {
        filteredItems() {
            if (this.orderSearch === "") {
                return this.orders;
            }
            const searchValue = this.orderSearch.toLowerCase().replace(/\s+/g, '');
            return this.orders.filter(order => {
                const name = order.firstName.toLowerCase().replace(/\s+/g, '') + order.lastName.toLowerCase().replace(/\s+/g, '')
                return name.includes(searchValue) || order.id.toString().includes(searchValue);
            });
        },
        paginatedItems() {
            const start = (this.currentPage - 1) * 20;
            return this.filteredItems.slice(start, start + 20);
        }
    },
    props: {
        orders: {
            type: Array,
        }
    }
}
</script>