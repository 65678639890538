<template>
    <div class="card-header">
        <h2 class="card-title">Collection Overview</h2>
    </div>
    <div v-for="location in collections" :key="location" class="card-body">
        <h2>
            {{ location.location }}
        </h2>
        <div class="row">
            <div v-for="date in location.dates" :key="date" class="col-4">
                <table class="table table-bordered table-sm">
                    <thead>
                    <th>
                        {{ date.date }}
                    </th>
                    <th class="w-1">
                        Booked
                    </th>
                    <th class="w-1">
                        Capacity
                    </th>
                    </thead>
                    <tbody>
                    <tr v-for="time in date.times" :key="time"
                        :class="{'bg-danger': time.booked === time.capacity}">
                        <td>
                            {{ time.time }}
                        </td>
                        <td>
                            {{ time.booked }}
                        </td>
                        <td>
                            {{ time.capacity }}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        collections: {
            type: Array,
        }
    }
}
</script>