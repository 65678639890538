<template>
    <div :id="this.id" class="card mt-5">
        <div class="card-header">
            <h3 class="card-title">Products</h3>
            <div class="card-options gap-4">
                <div class="input-group input-group-flat">
                    <span class="input-group-text"><IconSearch></IconSearch></span>
                    <input class="form-control" placeholder="Search..." v-model="productSearch" >
                    <span class="input-group-text">
                        <div @click="productSearch = ''" class="btn btn-sm btn-ghost-secondary"><IconX></IconX></div>
                    </span>
                </div>
                <button class="btn btn-primary" @click="addProduct">Add Product</button>
            </div>
        </div>
        <div class="card-body">
            <table class="table align-items-center">
                <thead>
                <tr>
                    <th scope="col">Product ID</th>
                    <th scope="col">Name</th>
                    <th scope="col">SKU</th>
                    <th scope="col">Enabled</th>
                    <th scope="col">Seasons</th>
                    <th scope="col">Quantity</th>
                </tr>
                </thead>
                <tbody class="table-tbody">
                <tr v-for="product in paginatedItems" :key="product.productID">
                    <td>
                        <router-link :to="`/pim/products/${product.productID}`">
                            {{ product.productID }}
                        </router-link>
                    </td>
                    <td>{{ product.name }}</td>
                    <td>
                        <router-link :to="`/pim/products/${product.productID}`">
                            {{ product.sku }}
                        </router-link>
                    </td>
                    <td>{{ product.enabled }}</td>
                    <td>{{ product.seasons }}</td>
                    <td>{{ product.qty }}</td>
                </tr>
                </tbody>
            </table>
        </div>
        <Pagination
            :totalItems="filteredItems.length"
            :itemsPerPage="20"
            :currentPage="currentPage"
            @page-changed="updateCurrentPage" />
    </div>
</template>

<script>
import api from '@/services/api';
import Pagination from "@/components/PaginationComponent.vue";
import {IconSearch, IconX} from '@tabler/icons-vue'
export default {
    components: {
        Pagination,
        IconSearch,
        IconX
    },
    props: {
        productData: {
            type: Array,
            default: null,
        },
        id: {
            type: String,
            default: "Products"
        }
    },
    data() {
        return {
            products: [],
            currentPage: 1,
            productSearch: ""
        };
    },
    watch: {
        productData(newVal) {
            if (newVal) {
                this.products = newVal;
            }
        },
        productSearch: {
            handler() {
                this.currentPage = 1;
            },
            immediate: true,
        },
    },
    computed: {
        filteredItems() {
            if (this.productSearch === "") {
                return this.products;
            }
            const searchValue = this.productSearch.toLowerCase().replace(/\s+/g, '');
            return this.products.filter(product => {
                const name = product.name.toLowerCase().replace(/\s+/g, '');
                const sku = product.sku.toLowerCase().replace(/\s+/g, '');
                return name.includes(searchValue) || sku.includes(searchValue);
            });
        },
        paginatedItems() {
            const start = (this.currentPage - 1) * 20;
            return this.filteredItems.slice(start, start + 20);
        }
    },
    async created() {
        if (!this.productData) {
            await this.fetchProducts();
        } else {
            this.groups = this.groupData
        }
    },
    methods: {
        updateCurrentPage(page) {
            this.currentPage = page;
        },
        async fetchProducts() {
            try {
                const response = await api.get('/api/products');
                this.products = response.data;
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        },
        addProduct() {
            this.$router.push('/pim/products/new');
        },
    },
};

</script>