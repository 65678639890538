<template>
    <BasePage>
        <div class="container">
            <PimOverview :types="types" :categories="categories" :groups="groups" :products="products"></PimOverview>
            <ProductsTable id="Products" :productData="products"></ProductsTable>
            <CategoriesTable id="Categories" @group-added="updateData" :categoryData="categories"></CategoriesTable>
            <GroupsTable id="Groups" @group-added="updateData" :groupData="groups"></GroupsTable>
            <TypesTable id="Types" @group-added="updateData" :type-data="types"></TypesTable>
        </div>
    </BasePage>
</template>

<script>
import TypesTable from "@/components/TypesTable.vue";

document.title = "PIM | CarneCloud"
import BasePage from '@/components/BasePage.vue';  // Adjust the import path if necessary
import PimOverview from "@/components/PimOverview.vue";
import ProductsTable from "@/components/ProductsTable.vue";
import CategoriesTable from "@/components/CategoriesTable.vue";
import GroupsTable from "@/components/GroupsTable.vue";
import api from '@/services/api';

export default {
    name: 'PimPage',
    components: {
        TypesTable,
        BasePage,
        PimOverview,
        ProductsTable,
        CategoriesTable,
        GroupsTable
    },
    data() {
        return {
            categories: [],
            groups: [],
            products: [],
            types: []
        };
    },
    async created() {
        await this.fetchData();
    },
    methods: {
        async updateData() {
            await this.fetchData();
        },
        async fetchData() {
            try {
                const [productsRes, groupsRes, categoriesRes, typesRes] = await Promise.all([
                    api.get('/api/products/all'),
                    api.get('/api/groups'),
                    api.get('/api/categories'),
                    api.get('/api/types'),
                ]);
                this.products = productsRes.data;
                this.groups = groupsRes.data;
                this.categories = categoriesRes.data;
                this.types = typesRes.data;
                return true
            } catch (error) {
                console.error('Error fetching data:', error);
                return false
            }
        },
    },
};
</script>
