<template>
    <div class="modal modal-blur fade" :id="modalId" tabindex="-1" role="dialog" aria-modal="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ title }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div v-if="errorMessage" class="alert alert-danger" role="alert">
                        {{ errorMessage }}
                    </div>
                    <slot></slot>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn me-auto" ref="closeButton" data-bs-dismiss="modal">{{ closeButton }}</button>
                    <button type="button" class="btn btn-primary" @click="$emit('submit')">{{ submitButton }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        errorMessage: {
            type: String,
            default: ''
        },
        modalId: {
            type: String,
            required: true
        },
        title: {
            type: String,
            default: 'Modal Title'
        },
        closeButton: {
            type: String,
            default: 'Close'
        },
        submitButton: {
            type: String,
            default: 'Submit'
        },
    }
}
</script>