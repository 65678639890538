<template>
    <BasePage>
        <div class="container">
            <div class="d-flex justify-content-center container">
                <div class="row w-100">
                    <div class="col-sm-12 col-md-3">

                    </div>
                    <div class="col-sm-12 col-md-6">
                        <div class="card w-100">
                            <form @submit.prevent="markReportCollected">
                                <div class="card-header">
                                    <div class="card-title">
                                        Mark Collected (Not delivery)
                                    </div>
                                </div>
                                <div v-if="error" class="card-body alert alert-important alert-danger">
                                    ERROR: {{errorMessage}}
                                </div>
                                <div v-if="success" class="card-body alert alert-important alert-success">
                                    Collected: {{successNumber}}
                                </div>
                                <div class="card-body">
                                    <!--<input class="form-">-->
                                    <div class="input-group mb-2">
                              <span class="input-group-text">
                                Order N<sup>o</sup>
                              </span>
                                        <input v-model="inputNumber" autofocus type="text" class="form-control" placeholder="123"
                                               autocomplete="off">
                                    </div>
                                </div>
                                <div class="card-footer">
                                    <div class="card-actions d-flex justify-content-end">
                                        <button @click="markReportCollected" class="btn btn-primary">Mark Collected
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-3">

                    </div>
                </div>

            </div>
        </div>
    </BasePage>
</template>

<script>

import BasePage from '@/components/BasePage.vue';  // Adjust the import path if necessary
import api from '@/services/api'
export default {
    name: 'CollectedPage',
    components: {
        BasePage,
    },
    data() {
        return {
            inputNumber: "",
            success: false,
            successNumber: "",
            error: false,
            errorMessage: ""
        };
    },
    async created() {
    },
    methods: {
        async markReportCollected() {
            this.error = false;
            try{
                const num = parseInt(this.inputNumber);
                console.log(typeof (num))
                if (!Number.isInteger(num)) {
                    throw new Error("Provided value is not an integer");
                }
                const res = await api.post(`/api/orderes/${num}/collected`);

                if(res.data.affectedRows !== 1) {
                    this.error = true;
                    this.errorMessage = "Error setting order status"
                    await this.pauseForASecond();
                } else {
                    this.successNumber = num;
                    this.success = true;
                }
                await this.pauseForASecond();
                this.inputNumber = "";
                this.success = false;
            } catch (e) {
                console.log(e);
                this.success = false;
                this.error = true;
                this.errorMessage = "Invalid order number"
                await this.pauseForASecond();
                this.inputNumber = "";
            }
        },
        async pauseForASecond() {
            return new Promise(resolve => setTimeout(resolve, 1000));
        }
    },
};
</script>
