<template>
    <div class="card">
        <div class="card-header">
            <h3 class="card-title">Collection Settings</h3>
            <div class="card-options">
                <button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addCollectionOptionModal">Add
                    Collection
                </button>
            </div>
        </div>
        <div class="card-body">
            <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item" v-for="(options, location) in organizedData" :key="location">
                    <a
                            class="nav-link"
                            :class="{ active: location === activeTab }"
                            data-bs-toggle="tab"
                            :href="'#tab-' + location"
                            role="tab"
                            @click="activeTab = location"
                    >
                        {{ location }}
                    </a>
                </li>
            </ul>
            <div class="tab-content">
                <div
                        v-for="(options, location) in organizedData"
                        :key="location"
                        class="tab-pane"
                        :class="{ active: location === activeTab }"
                        :id="'tab-' + location"
                        role="tabpanel"
                >
                    <div
                            v-for="(option) in options"
                            :key="option.option.collectionOptionId"
                            class="accordion mt-3"
                            :id="'accordion-' +  option.option.collectionOptionId"
                    >
                        <div class="accordion-item">
                            <h2 class="accordion-header"
                                :id="'heading-' +  option.option.collectionOptionId">
                                <button
                                        class="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        :data-bs-target="'#collapse-' + option.option.collectionOptionId"
                                        aria-expanded="true"
                                        :aria-controls="'collapse-' + option.option.collectionOptionId"
                                >
                                    {{ option.option.collectionDate }} (&pound;{{option.option.collectionFee/100}} Fee)
                                </button>
                            </h2>
                            <div
                                    :id="'collapse-' + option.option.collectionOptionId"
                                    class="accordion-collapse collapse"
                                    aria-labelledby="'heading-' + option.option.collectionOptionId"
                                    :data-bs-parent="'#accordion-' + option.option.collectionOptionId"
                            >
                                <div class="accordion-body">
                                    <div class="mb-2">

                                        <label class="form-check form-switch">
                                            <input class="form-check-input" type="checkbox" @change="updateOptionEnabled(option.option)" v-model="option.option.enabled">
                                            <span class="form-check-label">Enabled</span>
                                        </label>
                                    </div>
                                    <table class="mt-4 table table-striped table-hover">
                                        <thead>
                                        <tr>
                                            <th>Time</th>
                                            <th>Capacity</th>
                                            <th scope="col" class="w-1 text-end text-nowrap">
                                                <div @click="this.formData.collectionOption = option.option.collectionOptionId"
                                                     class="btn-ghost-primary btn btn-sm" data-bs-toggle="modal"
                                                     data-bs-target="#addCollectionModal">
                                                    <IconPlus></IconPlus>
                                                </div>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="slot in option.slots" :key="slot.collectionSlotId">
                                            <td>{{ slot.collectionTime }}</td>
                                            <td>{{ slot.capacity }}</td>
                                            <td>
                                                <div @click="setDeleteMessage(slot)" class="btn-ghost-danger btn btn-sm"
                                                     data-bs-toggle="modal" data-bs-target="#deleteSlotModal">
                                                    <IconTrash></IconTrash>
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <div @click="setDeleteMessage(option.option)" class="ml-4 btn-ghost-danger btn btn-sm"
                                         data-bs-toggle="modal" data-bs-target="#deleteOptionModal">
                                        <IconTrash></IconTrash> Delete Collection Option
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div class="modal modal-blur fade" id="addCollectionModal" tabindex="-1" role="dialog" aria-modal="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Add Collection Slot</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <form @submit.prevent="handleSlotSubmit">
                    <div class="modal-body">
                        <div v-if="errorMessage" class="alert alert-danger" role="alert">
                            {{ errorMessage }}
                        </div>
                        <div class="mb-3">
                            <label for="collectionTime" class="form-label">Collection Time</label>
                            <input
                                    type="text"
                                    class="form-control"
                                    id="collectionTime"
                                    v-model="formData.collectionTime"
                                    required
                            />
                        </div>
                        <div class="mb-3">
                            <label for="capacity" class="form-label">Capacity</label>
                            <input
                                    type="number"
                                    class="form-control"
                                    id="capacity"
                                    v-model="formData.capacity"
                                    required
                            />
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn me-auto" ref="closeButton" data-bs-dismiss="modal">cancel
                        </button>
                        <button type="submit" class="btn btn-primary">Add</button>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="modal modal-blur fade" id="addCollectionOptionModal" tabindex="-1" role="dialog" aria-modal="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Add Collection Option</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <form @submit.prevent="handleOptionSubmit">
                    <div class="modal-body">
                        <div v-if="errorMessage" class="alert alert-danger" role="alert">
                            {{ errorMessage }}
                        </div>
                        <div class="mb-3">
                            <label for="collectionTime" class="form-label">Collection Location</label>
                            <input
                                    type="text"
                                    class="form-control"
                                    id="collectionTime"
                                    v-model="optionFormData.collectionLocation"
                                    required
                            />
                        </div>
                        <div class="mb-3">
                            <label for="capacity" class="form-label">Collection Date (e.g. Weds - Dec 21)</label>
                            <input
                                    type="text"
                                    class="form-control"
                                    id="capacity"
                                    v-model="optionFormData.collectionDate"
                                    required
                            />
                        </div>
                        <div class="mb-3">
                            <label for="collectionOption" class="form-label">Collection Date Value (e.g. 21)</label>
                            <input
                                    type="number"
                                    class="form-control"
                                    id="collectionOption"
                                    v-model="optionFormData.collectionValue"
                                    required
                            />
                        </div>
                        <div class="mb-3">
                            <label for="collectionOption" class="form-label">Collection Fee (pence e.g. 200)</label>
                            <input
                                type="number"
                                class="form-control"
                                id="collectionOption"
                                v-model="optionFormData.collectionFee"
                                required
                            />
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn me-auto" ref="closeOptionButton" data-bs-dismiss="modal">cancel
                        </button>
                        <button type="submit" class="btn btn-primary">Add</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <DangerModal
            ref="modalDeleteOption"
            modalId="deleteOptionModal"
            title="Delete Option"
            :message="deleteMessage"
            closeButton="Cancel"
            submitButton="Delete"
            @submit="handleOptionDelete"
            :errorMessage="deleteErrorMessage"
    ></DangerModal>
    <DangerModal
            ref="modalDeleteSlot"
            modalId="deleteSlotModal"
            title="Delete Slot"
            :message="deleteMessage"
            closeButton="Cancel"
            submitButton="Delete"
            @submit="handleSlotDelete"
            :errorMessage="deleteErrorMessage"
    ></DangerModal>


</template>

<script>
import api from '@/services/api';
import {IconPlus, IconTrash} from '@tabler/icons-vue'
import DangerModal from "@/components/DangerModal.vue";

export default {
    data() {
        return {
            activeTab: '',
            activeAccordion: '',
            organizedData: {},
            deleteErrorMessage: '',
            slotToDelete: null,
            optionToDelete: null,
            collectionOptions: [],
            collectionSlots: [],
            deleteMessage: '',
            optionFormData: {
                collectionLocation: '',
                collectionDate: '',
                collectionValue: '',
                collectionFee: 0
            },
            formData: {
                collectionTime: '',
                collectionOption: '',
                capacity: ''
            },
            errorMessage: ''
        }
    },
    async created() {
        try {
            const [optionsResponse, slotsResponse] = await Promise.all([
                api.get('/api/collection/options'),
                api.get('/api/collection/slots')
            ]);
            this.collectionOptions = optionsResponse.data;
            this.collectionSlots = slotsResponse.data;
            this.activeTab = optionsResponse.data[0].collectionLocation
            this.organizeData();
        } catch (error) {
            console.error(error);
        }
    },
    methods: {
        setDeleteMessage(item) {
            console.log(item)
            if (item.collectionSlotId) {
                this.slotToDelete = item.collectionSlotId
                this.deleteMessage = `Are you sure you want to delete "${item.collectionTime}"`
            }
            if (item.collectionOptionId) {
                this.optionToDelete = item.collectionOptionId
                this.deleteMessage = `Are you sure you want to delete "${item.collectionLocation}"`
            }
        },
        async updateOptionEnabled(option) {
            const opt = {...option}
            if(opt.enabled) {
                opt.enabled = 1;
            } else {
                opt.enabled = 0;
            }
            await api.put(`/api/collection/options/${opt.collectionOptionId}`,opt)
        },
        async reloadData() {
            try {
                const [optionsResponse, slotsResponse] = await Promise.all([
                    api.get('/api/collection/options'),
                    api.get('/api/collection/slots')
                ]);
                this.collectionOptions = optionsResponse.data;
                this.collectionSlots = slotsResponse.data;
                this.organizeData();
            } catch (error) {
                console.error(error);
            }
        },
        async handleOptionDelete() {
            try {
                await this.deleteCollection(this.optionToDelete)
                this.optionToDelete = null;
                await this.reloadData();
                this.$refs.modalDeleteOption.$refs.closeButton.click();
                this.deleteErrorMessage = ""
            } catch (e) {
                this.deleteErrorMessage = e
            }
        },
        async handleSlotDelete() {
            try {
                await this.deleteSlot(this.slotToDelete)
                this.slotToDelete = null;
                await this.reloadData();
                this.$refs.modalDeleteSlot.$refs.closeButton.click();
                this.deleteErrorMessage = ""
            } catch (e) {
                this.deleteErrorMessage = e
            }
        },
        async handleSlotSubmit() {
            if (this.formData.collectionOption && this.formData.collectionTime && this.formData.capacity) {
                try {
                    await this.addSlot(this.formData);  // Assuming addSlot is your method to add a new slot
                    this.formData = {collectionTime: '', collectionOption: '', capacity: ''};  // Reset form
                    this.$refs.closeButton.click();
                    await this.reloadData();  // Reload data from the API
                    this.errorMessage = '';
                } catch (error) {
                    this.errorMessage = error;
                }
            } else {
                this.errorMessage = "Please fill in all fields"
            }
        },
        async handleOptionSubmit() {
            if (this.optionFormData.collectionLocation && this.optionFormData.collectionDate && this.optionFormData.collectionValue && this.optionFormData.collectionFee) {
                try {
                    await this.addCollection(this.optionFormData);
                    this.optionFormData = {
                        collectionLocation: '',
                        collectionDate: '',
                        collectionValue: '',
                        collectionFee: 0
                    }
                    this.errorMessage = '';
                    this.$refs.closeOptionButton.click();
                    await this.reloadData();
                } catch (error) {
                    this.errorMessage = error;
                }
            } else {
                this.errorMessage = "Please fill in all fields"
            }
        },
        async addCollection(newCollection) {
            try {
                const response = await api.post('/api/collection/options', newCollection);
                this.collectionOptions.push({...newCollection, collectionOptionId: response.data.collectionOptionId});
                this.organizeData();
            } catch (error) {
                console.error(error);
            }
        },

        async updateCollection(updatedCollection) {
            try {
                await api.put(`/api/collection/options/${updatedCollection.collectionOptionId}`, updatedCollection);
                const index = this.collectionOptions.findIndex(
                    option => option.collectionOptionId === updatedCollection.collectionOptionId
                );
                if (index !== -1) {
                    this.$set(this.collectionOptions, index, updatedCollection);
                    this.organizeData();
                }
            } catch (error) {
                console.error(error);
            }
        },

        async deleteCollection(collectionOptionId) {
            try {
                await api.delete(`/api/collection/options/${collectionOptionId}`);
                const index = this.collectionOptions.findIndex(
                    option => option.collectionOptionId === collectionOptionId
                );
                if (index !== -1) {
                    this.collectionOptions.splice(index, 1);
                    this.organizeData();
                }
            } catch (error) {
                console.error(error);
            }
        },
        async addSlot(newSlot) {
            try {
                const response = await api.post('/api/collection/slots', newSlot);
                this.collectionSlots.push({...newSlot, collectionSlotId: response.data.collectionSlotId});
                this.organizeData();
            } catch (error) {
                console.error(error);
            }
        },

        async updateSlot(updatedSlot) {
            try {
                await api.put(`/api/collection/slots/${updatedSlot.collectionSlotId}`, updatedSlot);
                const index = this.collectionSlots.findIndex(
                    slot => slot.collectionSlotId === updatedSlot.collectionSlotId
                );
                if (index !== -1) {
                    this.$set(this.collectionSlots, index, updatedSlot);
                    this.organizeData();
                }
            } catch (error) {
                console.error(error);
            }
        },

        async deleteSlot(collectionSlotId) {
            try {
                await api.delete(`/api/collection/slots/${collectionSlotId}`);
                const index = this.collectionSlots.findIndex(
                    slot => slot.collectionSlotId === collectionSlotId
                );
                if (index !== -1) {
                    this.collectionSlots.splice(index, 1);
                    this.organizeData();
                }
            } catch (error) {
                this.deleteErrorMessage = error.message
                console.error(error);
            }
        },
        organizeData() {
            const organized = this.collectionOptions.reduce((acc, option) => {
                const location = option.collectionLocation;
                const date = option.collectionDate;
                if (!acc[location]) acc[location] = {};
                if (!acc[location][date]) acc[location][date] = {
                    option: option,
                    slots: []
                };
                const relevantSlots = this.collectionSlots.filter(
                    slot => slot.collectionOption === option.collectionOptionId
                );
                acc[location][date].slots = relevantSlots;
                return acc;
            }, {});
            console.log(this.organizedData)
            this.organizedData = organized;
        },
    },
    components: {
        DangerModal,
        IconPlus,
        IconTrash
    }

};
</script>

