<template>
    <div class="card-header">
        <h2 class="card-title">Products Report</h2>
    </div>
    <div class="mb-4">
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">Select Collection Locations</h3>
                <div class="card-options">
                    <div v-show="!showLocations" @click="showLocations = true" class="btn btn-sm btn-ghost-primary">Show</div>
                    <div v-show="showLocations" @click="showLocations = false" class="btn btn-sm btn-ghost-primary">Hide</div>
                </div>
            </div>
            <div v-show="showLocations" class="list-group list-group-flush" id="collectionLocationList">
                <div class="list-group-item" v-for="(location, index) in collectionLocations" :key="index">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" :value="location" v-model="selectedLocations" :id="'location' + index">
                        <label class="form-check-label" :for="'location' + index">
                            {{ location }}
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="card mt-3">
            <div class="card-header">
                <h3 class="card-title">Select Collection Dates</h3>
                <div class="card-options">
                    <div v-show="!showDates" @click="showDates = true" class="btn btn-sm btn-ghost-primary">Show</div>
                    <div v-show="showDates" @click="showDates = false" class="btn btn-sm btn-ghost-primary">Hide</div>
                </div>
            </div>
            <div v-show="showDates" class="list-group list-group-flush" id="collectionLocationList">
                <div class="list-group-item" v-for="(date, index) in collectionDates" :key="index">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" :value="date" v-model="selectedCollectionDates" :id="'date' + index">
                        <label class="form-check-label" :for="'date' + index">
                            {{ date }}
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="card mt-3">
            <div class="card-header">
                <h3 class="card-title">Select Collection Times</h3>
                <div class="card-options">
                    <div v-show="!showTimes" @click="showTimes = true" class="btn btn-sm btn-ghost-primary">Show</div>
                    <div v-show="showTimes" @click="showTimes = false" class="btn btn-sm btn-ghost-primary">Hide</div>
                </div>
            </div>
            <div v-show="showTimes" class="list-group list-group-flush" id="collectionLocationList">
                <div class="list-group-item" v-for="(time, index) in collectionTimes" :key="index">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" :value="time" v-model="selectedTimes" :id="'time' + index">
                        <label class="form-check-label" :for="'time' + index">
                            {{ time }}
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="card mt-3">
            <div class="card-header">
                <h3 class="card-title">Select Delivery Dates</h3>
                <div class="card-options">
                    <div v-show="!showDeliveryDates" @click="showDeliveryDates = true" class="btn btn-sm btn-ghost-primary">Show</div>
                    <div v-show="showDeliveryDates" @click="showDeliveryDates = false" class="btn btn-sm btn-ghost-primary">Hide</div>
                </div>
            </div>
            <div v-show="showDeliveryDates" class="list-group list-group-flush" id="collectionLocationList">
                <div class="list-group-item" v-for="(date, index) in deliveryDates" :key="index">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" :value="date" v-model="selectedDeliveryDates" :id="'date' + index">
                        <label class="form-check-label" :for="'date' + index">
                            {{ date }}
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import api from '@/services/api'
export default {
    data() {
        return {
            showLocations: false,
            showTimes: false,
            showDates: false,
            showDeliveryDates: false,
            collectionLocations: [],
            selectedLocations: [],
            collectionDates: [],
            selectedCollectionDates: [],
            collectionTimes: [],
            selectedTimes: [],
            deliveryDates: [],
            selectedDeliveryDates: []
        }
    },
    async mounted() {
        const res = await api.get("/api/reports/filterOptions");
        console.log(res.data);
        this.collectionLocations = res.data.collectionLocations;
        this.selectedLocations = res.data.collectionLocations;
        this.collectionDates = res.data.collectionDates;
        this.selectedCollectionDates = res.data.collectionDates;
        this.collectionTimes = res.data.collectionTimes;
        this.selectedTimes = res.data.collectionTimes;
        this.deliveryDates = res.data.deliveryDates;
        this.selectedDeliveryDates = res.data.deliveryDates;
    }
}
</script>