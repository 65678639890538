<template>
    <div v-for="p in pages" :key="p" class="a4-sheet">
        <template v-for="l in p" :key="l">
            <div class="collectionSlip">
                <div class="row">
                    <div class="col col-12 text-center">
                        <img src="https://static.larkhallbutchers.co.uk/logo.png" width="150" style="margin-top: -8px">
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="row">
                            <div class="col-2">
                                <span class="h1">{{ l.id }}</span><br/><span style="font-size: 8pt">{{ l.firstName }} {{
                                l.lastName
                                }}</span>
                            </div>
                            <div class="col-2 border-start px-6">
                            <span class="h1">{{ formatOrdinal(l.collectionDate) }} {{
                                formatOrdinal(l.deliveryDate)
                                }}</span><br/>
                                <small v-if="l.collectionOption === 'Collection'"
                                       :style="bgColor(l.collectionLocation.split(' ')[0])"
                                       class="p-1">{{ l.collectionLocation.split(" ")[0] }}</small>
                                <small v-if="l.collectionOption === 'Delivery'"
                                       class="bg-danger p-1 text-danger-fg">Delivery</small>
                            </div>
                            <div class="col co-6 border-start" style="padding-left: 3rem; margin-left: 3rem">
                                Deposit: &pound;{{ l.depositAmount / 100 }} <br/>
                                <span v-if="l.deliveryFee">Delivery Fee: &pound;{{ l.deliveryFee / 100 }}</span>
                                <span v-if="l.collectionFee">Collection Fee: &pound;{{ l.collectionFee / 100 }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="col col-2">
                        <img height="100" style="margin-top: -28px"
                             :src="'https://qrcode-service.larkhall-butchers.workers.dev/'+l.id"/>
                    </div>
                </div>
                <div class="row" v-if="l.orderComments">
                    <div class="border-1 border-top border-bottom col-12">
                        <i class="italic" style="font-size: 10pt;">{{ l.orderComments }}</i>
                    </div>
                </div>
                <div class="row">
                    <table class="table table-bordered">
                        <tr v-bind:key="i.id" v-for="i in l.items">
                            <td class="px-1" style="width: calc(50% - 155px)">{{ i.item }} &nbsp;</td>
                            <td class="border-start px-1" style="width: calc(50% - 155px)">{{ i.extraInfo }} &nbsp;</td>
                            <td class="border-start px-1" style="width: 70px">{{ i.size }}
                                <unit style="margin-left: -10px" v-if="i.unit !== 'each'">{{ i.unit }}</unit>
                            </td>
                            <td class="border-start px-1" style="width: 100px">
                                <unit v-if="i.unit === 'each'">{{ i.size }}</unit>
                                <unit v-if="i.allocatedWeight">{{ i.allocatedWeight }}</unit>
                            </td>
                            <td v-if="i.allocatedWeight === null && i.unit !== 'each'" class="border-start px-1"
                                style="width: 80px">&pound; &nbsp; &nbsp;
                                &nbsp; &nbsp; &nbsp;
                                &nbsp; &nbsp; &nbsp;
                            </td>
                            <td v-if="i.unit === 'each'" class="border-start px-1" style="width: 80px">
                                {{ formatGBP(i.price * i.size) }}
                            </td>
                            <td v-if="i.allocatedWeight" class="border-start px-1" style="width: 80px">
                                {{ formatGBP(i.price * i.allocatedWeight) }}
                            </td>
                        </tr>
                        <tr>
                            <td style="border-bottom: 0pt"></td>
                            <td style="border-bottom: 0pt"></td>
                            <td style="border-bottom: 0pt"></td>
                            <td style="padding-top: 20px; vertical-align: bottom">Total:</td>
                            <td style="padding-top: 20px; vertical-align: bottom"><span style="padding: 0"
                                                                                        v-if="!l.total">&pound;</span>
                                <span v-if="l.total">{{ formatGBP(l.total) }}</span>
                            </td>
                        </tr>
                        <tr v-if="l.total">
                            <td style="border-bottom: 0pt; border-top: 0;"></td>
                            <td style="border-bottom: 0pt; border-top: 0"></td>
                            <td style="border-bottom: 0pt; border-top: 0"></td>
                            <td style="padding-top: 10px; vertical-align: bottom">To Pay:</td>
                            <td style="padding-top: 10px; vertical-align: bottom">
                                <span>{{ formatGBP(l.total - l.depositAmount) }}</span>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
import api from '@/services/api'

export default {
    name: "LabelPage",
    data() {
        return {
            pages: [],
            excludingCollected: true
        }
    },
    async mounted() {
        let res = null;
        if (this.$route.params.filter) {
            res = await api.get(`/api/slips/${this.$route.params.filter}`);
        }
        let data = res.data;
        if (this.$route.query.excludeCollected === 'true') {
            data = res.data.filter(x => x.collected !== 1)
        }
        this.pages = []
        for (let i = 0; i < data.length; i += 2) {
            this.pages.push(data.slice(i, i + 2));

        }
    },
    methods: {
        formatGBP(pence) {
            const pounds = pence / 100;
            return new Intl.NumberFormat('en-GB', {
                style: 'currency',
                currency: 'GBP'
            }).format(pounds);
        },
        formatOrdinal(str) {
            // Remove non-numeric characters from the string
            try {
                let number = str.replace(/\D/g, '');

                // Convert the cleaned string to a number
                number = parseInt(number, 10);

                // Check if the number is valid
                if (isNaN(number)) {
                    //throw new Error('No valid number found in string');
                    return ""
                }

                // Determine the ordinal suffix
                let suffix;
                if (number % 10 === 1 && number % 100 !== 11) {
                    suffix = 'st';
                } else if (number % 10 === 2 && number % 100 !== 12) {
                    suffix = 'nd';
                } else if (number % 10 === 3 && number % 100 !== 13) {
                    suffix = 'rd';
                } else {
                    suffix = 'th';
                }

                // Combine the number and the ordinal suffix
                return number + suffix;

            } catch (e) {
                console.log(e)
                return ""
            }
        },
        stringToColour(str) {
            let hash = 0;
            for (let i = 0; i < str.length; i++) {
                hash = hash * 31 + str.charCodeAt(i);
                hash |= 0;  // Convert to 32-bit integer
            }
            let colour = '#';
            for (let i = 0; i < 3; i++) {
                const value = (hash >> (i * 8)) & 0xFF;
                colour += ('00' + value.toString(16)).substr(-2);
            }
            return colour;
        },
        getLuminance(hexColor) {
            const r = parseInt(hexColor.slice(1, 3), 16) / 255;
            const g = parseInt(hexColor.slice(3, 5), 16) / 255;
            const b = parseInt(hexColor.slice(5, 7), 16) / 255;
            return (0.299 * r + 0.587 * g + 0.114 * b);
        },
        bgColor(str) {
            const backgroundColor = this.stringToColour(str);
            // Using a luminance threshold of 0.5 to decide on text color.
            const textColor = this.getLuminance(backgroundColor) > 0.5 ? 'black' : 'white';
            return {
                backgroundColor,
                color: textColor,
            };
        }
    }
}
</script>
<style scoped>
* {
    background: white;
}

.extraInfo {
    background: #C3984A;
}

/* Define the A4 size and setup margins */
.a4-sheet {
    width: 210mm;
    height: 297mm;
    padding: 4.5mm 7mm;
    box-sizing: border-box;
    font-family: Arial, sans-serif;

}

.collectionSlip {
    width: 200mm;
    height: 141mm;
    border: solid 1pt #333;
    padding: 10px;
    margin-top: 0;
    margin-left: 0;
    margin-right: 2mm;

}

.collectionSlip:nth-child(2n) {
    margin-top: 5mm;
}

/* Define the grid and label styling */
.label {
    width: 63.5mm;
    height: 72mm;
    display: inline-block;
    box-sizing: border-box;
    margin-right: 2mm;
    margin-bottom: 0mm;
    margin-top: 0mm;
    border-radius: 1.5mm;
    vertical-align: top;
    border: 1pt solid black;
    padding: 2mm;
    overflow: hidden;

}


/* Remove right margin for every third label */
.label:nth-child(3n) {
    margin-right: 0mm; /* Width of label + gap */
}

@media print {
    /* This will remove the background color when printed */
    .label {
        background: none;
    }

    .extraInfo {
        background: #C3984A;
    }

    /* This ensures the entire sheet is scaled to fit on one A4 page */
    body, html {
        height: 100%;
        width: 100%;
        margin: 0;
        padding: 0;
    }

    .a4-sheet {
        box-sizing: border-box;
        height: 297mm; /* A4 height */
        width: 210mm;

        /* A4 width */
    }

    @page {
        margin: 0;
    }

    .label {

    }
}
</style>