<!-- AddGroup.vue -->
<template>
    <div>
        <div class="mb-3">
            <label for="groupName" class="form-label">Group Name</label>
            <input type="text" class="form-control" id="groupName" v-model="groupName" required
                   :class="{ 'is-invalid': nameInvalid }">
        </div>
        <div class="mb-3">
            <label for="groupSlug" class="form-label">Group Slug</label>
            <input type="text" class="form-control" id="groupSlug" v-model="groupSlug" required
                   :class="{ 'is-invalid': slugInvalid }">
            <div class="invalid-feedback" v-if="slugInvalid">
                Slug can only contain lowercase letters and hyphens.
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            groupName: '',
            groupSlug: '',
            slugInvalid: false,
            nameInvalid: false
        };
    },
    watch: {
        groupSlug(newSlug) {
            this.validateSlug(newSlug);
        },
        groupName(newName) {
            this.validateName(newName)
        }

    },
    methods: {
        validateSlug(slug) {
            const regex = /^[a-z-]+$/;
            this.slugInvalid = !regex.test(slug);
        },
        validateName(name) {
            this.nameInvalid = (name === '')
        },
        getGroupData() {
            if (!(this.name === '') || !/^[a-z-]+$/.test(this.groupSlug)) {
                return {
                    groupName: this.groupName,
                    groupSlug: this.groupSlug
                };
            }
            return null;
        },
        reset() {
            this.groupName = '';
            this.groupSlug = '';
            this.slugInvalid = false;
            this.nameInvalid = false;
        },
    }
}
</script>
