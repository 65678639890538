<template>
    <BasePage>
        <div class="container">
            <OrdersOverview :orders="orders"></OrdersOverview>
            <OrdersTable :orders="orders"></OrdersTable>
        </div>
    </BasePage>
</template>

<script>
document.title = "PIM | CarneCloud"
import BasePage from '@/components/BasePage.vue';  // Adjust the import path if necessary
import OrdersOverview from "@/components/OrdersOverview.vue";
import OrdersTable from "@/components/OrdersTable.vue";
import api from '@/services/api';

export default {
    name: 'OrdersPage',
    components: {
        BasePage,
        OrdersOverview,
        OrdersTable,
    },
    data() {
        return {
            orders: [],
        };
    },
    async created() {
        await this.fetchData();
    },
    methods: {
        async updateData() {
            await this.fetchData();
        },
        async fetchData() {
            try {
                const ordersRes = await api.get('/api/orders')
                this.orders = ordersRes.data;
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },
    },
};
</script>
