<template>
    <BasePage>
        <div class="container">
            <CollectionSettings></CollectionSettings>
        </div>
    </BasePage>
</template>

<script>
document.title = "PIM | CarneCloud"
import BasePage from '@/components/BasePage.vue';  // Adjust the import path if necessary
import CollectionSettings from "@/components/CollectionSettings.vue";

export default {
    name: 'SettingsPage',
    components: {
        BasePage,
        CollectionSettings,
    },
    data() {
        return {
        };
    },
    async created() {
    },
    methods: {

    },
};
</script>
