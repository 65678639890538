<template>
    <div :id="this.id" class="card mt-5 mb-5">
        <div class="card-header">
            <h3 class="card-title">Types</h3>
            <div class="card-options">
                <button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addTypeModal">Add Type
                </button>
            </div>
        </div>
        <div class="px-3 mt-3">
            <table class="table table-vcenter">
                <thead>
                <tr>
                    <th scope="col">Type ID</th>
                    <th scope="col">Name</th>
                    <th scope="col">Slug</th>
                    <th scope="col" class="w-1 text-end text-nowrap"></th>
                </tr>
                </thead>
                <tbody class="table-tbody">
                <tr v-for="type in types" :key="type.typeId">
                    <td>{{ type.typeId }}</td>
                    <td>{{ type.typeName }}</td>
                    <td>{{ type.typeSlug }}</td>
                    <td class="text-end text-nowrap">
                        <button class="btn btn-icon btn-ghost-danger" data-bs-toggle="modal"
                                data-bs-target="#deleteTypeModal" @click="askToDeleteType(type)">
                            <IconTrash></IconTrash>
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
    <FormModal
            ref="modalComponent"
            modalId="addTypeModal"
            title="Add a new type"
            closeButton="Cancel"
            submitButton="Add Type"
            @submit="handleAddTypeSubmit"
            :errorMessage="errorMessage"
    >
        <AddType ref="addTypeComponent"></AddType>
    </FormModal>
    <DangerModal
            ref="modalDeleteComponent"
            modalId="deleteTypeModal"
            title="Delete Type"
            :message="deleteTypeMessage"
            closeButton="Cancel"
            submitButton="Delete Type"
            @submit="handleTypeDelete"
            :errorMessage="deleteErrorMessage"
    >
    </DangerModal>
</template>

<script>
import api from '@/services/api';
import FormModal from "@/components/FormModal.vue";
import AddType from '@/components/AddType.vue';
import {IconTrash} from '@tabler/icons-vue'
import DangerModal from "@/components/DangerModal.vue";

export default {
    props: {
        typeData: {
            type: Array,
            default: null,
        },
        id: {
            type: String,
            default: "Types"
        }
    },
    data() {
        return {
            types: [],
            isFormValid: false,
            errorMessage: "",
            deleteTypeMessage: "",
            deleteTypeId: null,
            deleteErrorMessage: null,
        };
    },
    watch: {
        typeData(newVal) {
            if (newVal) {
                this.types = newVal;
            }
        },
    },
    async created() {
        if (!this.typeData) {
            await this.fetchTypes();
        } else {
            this.types = this.typeData
        }
    },
    methods: {
        askToDeleteType(type) {
            this.deleteTypeMessage = `Are you sure you want to delete type "${type.typeName}"? This could have an impact on products associated with this type.`
            this.deleteTypeId = type.groupId;
        },
        async fetchGroups() {
            try {
                const response = await api.get('/api/types');
                this.types = response.data;
            } catch (error) {
                console.error('Error fetching types:', error);
            }
        },
        async handleTypeDelete() {
            if (this.deleteTypeId) {
                try {
                    await api.delete(`/api/types/${this.deleteTypeId}`);
                    this.$emit('group-added')
                    this.$refs.modalDeleteComponent.$refs.closeButton.click();
                    this.deleteTypeId = null;
                    this.deleteErrorMessage = ""
                } catch (error) {
                    console.error('Error deleting type:', error);
                    this.deleteErrorMessage = `Error deleting type: ${error.message}`
                }
            }
        },
        async handleAddTypeSubmit() {
            const addTypeComponent = this.$refs.addTypeComponent;
            const typeData = addTypeComponent.getTypeData();
            if (typeData) {
                try {
                    await api.post('/api/types', typeData);
                    this.$refs.modalComponent.$refs.closeButton.click();
                    this.$refs.addTypeComponent.reset();
                    this.$emit('group-added')
                    this.errorMessage = ""
                } catch (error) {
                    console.error('Error adding type:', error);
                    this.errorMessage = "Invalid data"
                }
            } else {
                this.errorMessage = "Invalid data"
                console.error('Invalid data');
            }
        },
    },
    components: {
        DangerModal,
        FormModal,
        AddType,
        IconTrash
    },
    emits: ['groupAdded'],
};

</script>
