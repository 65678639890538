<template>
    <BasePage>
        <div class="container">
            <div class="card">
                <div class="card-header">
                    <div class="card-title">
                        Deliveries
                    </div>
                </div>
                <div v-bind:key="d" v-for="d of dates" class="card-body">
                    <div class="d-flex w-100 align-content-between">
                        <div class="flex-grow-1"><h3>{{ d.deliveryDate }}</h3></div>
                        <div v-if="showDateVal !== d.deliveryDate" @click="showDate(d.deliveryDate)"
                             class="btn btn-ghost-primary">SHOW
                            <IconArrowBadgeDownFilled></IconArrowBadgeDownFilled>
                        </div>
                        <div v-if="showDateVal === d.deliveryDate" @click="hideDate(d.deliveryDate)"
                             class="btn btn-ghost-primary">HIDE
                            <IconArrowBadgeUpFilled></IconArrowBadgeUpFilled>
                        </div>
                    </div>
                    <div v-if="showDateVal === d.deliveryDate" class="border-top mt-1 pt-1 mb-2">
                        <button class="mt-2 mb-2 btn btn-danger" disabled>Send all Balance Emails</button> &nbsp;
                        <button class="mt-2 mb-2 btn btn-primary" data-bs-toggle="modal"
                                data-bs-target="#paymentModal" @click="sendReadyEmails()">Send Ready Balance Emails</button>
                        <div>
                            {{paid}}/{{orders.length}} Paid
                        </div>
                        <div class="w-100" style="text-align: right">
                            <button class="btn-ghost-primary btn" @click="showDate(d.deliveryDate)"><IconRefresh></IconRefresh></button>
                        </div>
                        <table class="table table-bordered">
                            <thead>
                            <th>
                                Order N°
                            </th>
                            <th>
                                Address
                            </th>
                            <th>
                                Balance Status
                            </th>
                            <th>
                                Balance Link
                            </th>
                            <th>
                                Balance Email Sent
                            </th>
                            <th>
                                Order Ready
                            </th>
                            </thead>
                            <tbody>
                            <tr v-bind:key="order.id" v-for="order in orders">
                                <td>
                                    <a :href="'/orders/'+order.id" target="_blank">
                                        {{order.id}} <IconExternalLink></IconExternalLink>
                                    </a>
                                </td>
                                <td>
                                    {{order.deliveryAddress}}
                                </td>
                                <td>
                                    <span v-if="order.balanceStatus">{{order.balanceStatus}}</span>
                                    <span v-if="!order.balanceStatus">Not Paid</span>
                                </td>
                                <td>
                                    <a :href="'https://christmas.larkhallbutchers.co.uk/payment/'+order.orderGUID" target="_blank">https://christmas.larkhallbutrchers.co.uk/payment/{{order.orderGUID}}</a>
                                </td>
                                <td>
                                    <span v-if="order.paymentEmailSent" class="text-success"><IconCheck></IconCheck></span>
                                    <span v-if="!order.paymentEmailSent" class="text-warning"><IconX></IconX></span>
                                </td>
                                <td>
                                    <span v-if="order.canPay" class="text-success"><IconCheck></IconCheck></span>
                                    <span v-if="!order.canPay" class="text-warning"><IconX></IconX></span>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="card mt-4">
                <div class="card-header">
                    <div class="card-title">
                        Satellite Locations
                    </div>
                </div>
                <div v-bind:key="d" v-for="d of locations" class="card-body">
                    <div class="d-flex w-100 align-content-between">
                        <div class="flex-grow-1"><h3>{{ d.collectionLocation }}</h3></div>
                        <div v-if="showLocationVal !== d.collectionLocation" @click="showLocation(d.collectionLocation)"
                             class="btn btn-ghost-primary">SHOW
                            <IconArrowBadgeDownFilled></IconArrowBadgeDownFilled>
                        </div>
                        <div v-if="showLocationVal === d.collectionLocation" @click="hideLocation(d.collectionLocation)"
                             class="btn btn-ghost-primary">HIDE
                            <IconArrowBadgeUpFilled></IconArrowBadgeUpFilled>
                        </div>
                    </div>
                    <div v-if="showLocationVal === d.collectionLocation" class="border-top mt-1 pt-1 mb-2">
                        <button class="mt-2 mb-2 btn btn-danger" disabled>Send all Balance Emails</button> &nbsp;
                        <button class="mt-2 mb-2 btn btn-primary" data-bs-toggle="modal"
                                data-bs-target="#paymentModal" @click="sendReadyEmails()">Send Ready Balance Emails</button>
                        <div>
                            {{paid}}/{{orders.length}} Paid
                        </div>
                        <div class="w-100" style="text-align: right">
                            <button class="btn-ghost-primary btn" @click="showDate(d.collectionLocation)"><IconRefresh></IconRefresh></button>
                        </div>
                        <table class="table table-bordered">
                            <thead>
                            <th>
                                Order N°
                            </th>
                            <th>
                                Address
                            </th>
                            <th>
                                Balance Status
                            </th>
                            <th>
                                Balance Link
                            </th>
                            <th>
                                Balance Email Sent
                            </th>
                            <th>
                                Order Ready
                            </th>
                            </thead>
                            <tbody>
                            <tr v-bind:key="order.id" v-for="order in orders">
                                <td>
                                    <a :href="'/orders/'+order.id" target="_blank">
                                        {{order.id}} <IconExternalLink></IconExternalLink>
                                    </a>
                                </td>
                                <td>
                                    {{order.deliveryAddress}}
                                </td>
                                <td>
                                    <span v-if="order.balanceStatus">{{order.balanceStatus}}</span>
                                    <span v-if="!order.balanceStatus">Not Paid</span>
                                </td>
                                <td>
                                    <a :href="'https://christmas.larkhallbutchers.co.uk/payment/'+order.orderGUID" target="_blank">https://christmas.larkhallbutrchers.co.uk/payment/{{order.orderGUID}}</a>
                                </td>
                                <td>
                                    <span v-if="order.paymentEmailSent" class="text-success"><IconCheck></IconCheck></span>
                                    <span v-if="!order.paymentEmailSent" class="text-warning"><IconX></IconX></span>
                                </td>
                                <td>
                                    <span v-if="order.canPay" class="text-success"><IconCheck></IconCheck></span>
                                    <span v-if="!order.canPay" class="text-warning"><IconX></IconX></span>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <action-modal modal-id="paymentModal" ref="paymentModal" title="Send Emails"
                      closeButton="Cancel"
                      submitButton="Send"
                      :message="sendMessage"
                      :locked="locked"
                      @submit="sendEmails"
                      :errorMessage="sendingMessage">
        </action-modal>
    </BasePage>
</template>

<script>
import BasePage from '@/components/BasePage.vue';  // Adjust the import path if necessary
import api from "@/services/api";
import {IconArrowBadgeDownFilled, IconArrowBadgeUpFilled, IconExternalLink,
    IconCheck, IconX, IconRefresh} from "@tabler/icons-vue"
import ActionModal from "@/components/ActionModal.vue";

export default {
    name: 'DeliveryPage',
    components: {
        ActionModal,
        BasePage,
        IconArrowBadgeDownFilled,
        IconArrowBadgeUpFilled,
        IconExternalLink,
        IconCheck,
        IconX,
        IconRefresh
    },
    data() {
        return {
            dates: [],
            locations: [],
            showDateVal: null,
            orders: [],
            sendingMessage: "",
            sendMessage: "",
            locked: false,
            toSend: [],
            showLocationVal: "",
            paid: 0
        };
    },
    async mounted() {
        const res = await api.get('/api/delivery/deliveryDates');
        const r = await api.get('/api/delivery/deliveryLocations');
        this.dates = this.sortDates(res.data.dates);
        this.locations = r.data.locations;
    },
    methods: {
        async showDate(sd) {
            this.showLocationVal = null;
            this.showDateVal = sd;
            this.orders = [];
            const res = await api.post('/api/delivery/dateOrders', {deliveryDate: sd})
            this.orders = res.data;
            this.paid = (res.data.filter(x => x.balanceStatus === 'paid')).length;
        },
        async showLocation(sd) {
            this.showLocationVal = sd;
            this.showDateVal = null;
            this.orders = [];
            const res = await api.post('/api/delivery/locationOrders', {deliveryDate: sd})
            this.orders = res.data;
            this.paid = (res.data.filter(x => x.balanceStatus === 'paid')).length;
        },
        hideLocation() {
          this.orders = [];
          this.showLocationVal = null;
          this.showDateVal = null;
        },
        hideDate() {
            this.orders = [];
            this.showLocationVal = null;
            this.showDateVal = null;
        },
        sortDates(arr) {
            const currentYear = new Date().getFullYear();

            return arr.sort((a, b) => {
                const dateA = new Date(`${a.deliveryDate}, ${currentYear}`);
                const dateB = new Date(`${b.deliveryDate}, ${currentYear}`);
                return dateA - dateB;
            })
        },
        async sendReadyEmails() {
            this.toSend = this.orders.filter(o => { return o.canPay })
            //console.log(or)
                //&& !o.paymentEmailSent
            console.log(this.toSend);
            this.sendMessage = `Are you sure you want to send ${this.toSend.length} payment emails?`
        },
        async sendEmails() {
            this.locked = true;
            for(const i in this.toSend) {
                this.sendMessage = `Sending ${parseInt(i)+1} of ${this.toSend.length}...`
                await api.post(`/api/billing/send-email/${this.toSend[i].orderGUID}`)
                await this.pauseForASecond();
            }
            this.sendingMessage = "Sending Complete"
            await this.showDate(this.showDateVal);
            this.locked = false;
            await this.pauseForASecond();
            this.$refs.paymentModal.$refs.closeButton.click();
            //alert("Done!")
            //this.sendingMessage = "Sending 1/15..."
        },
        async pauseForASecond() {
            return new Promise(resolve => setTimeout(resolve, 1000));
        }
    },
};
</script>
