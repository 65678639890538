<template>
    <div class="d-flex flex-column min-vh-100">
        <nav id="basePage" class="navbar navbar-expand">
            <div class="container">
                <a class="navbar-brand" href="/">
                    <img v-if="currentTheme === 'dark'" height="50" src="/logo-dark.svg" />
                    <img v-if="currentTheme === 'light'" height="50" src="/logo-light.svg" />
                    CarneCloud</a>
                <div class="navbar-nav flex-row order-md-last">
                    <div class="d-none d-md-flex mx-4">
                        <a href="#" @click.prevent="toggleTheme" v-if="currentTheme=== 'light'" class="nav-link px-0" data-bs-toggle="tooltip" data-bs-placement="bottom" aria-label="Enable dark mode" data-bs-original-title="Enable dark mode">
                           <IconMoonFilled></IconMoonFilled>
                        </a>
                        <a href="#" class="nav-link px-0" @click.prevent="toggleTheme" v-if="currentTheme === 'dark'" data-bs-toggle="tooltip" data-bs-placement="bottom" aria-label="Enable light mode" data-bs-original-title="Enable light mode">
                            <IconSunFilled></IconSunFilled>
                        </a>
                    </div>
                    <div class="nav-item dropdown">
                        <a href="#" class="nav-link d-flex lh-1 text-reset p-0" data-bs-toggle="dropdown" aria-label="Open user menu" aria-expanded="true">
                            <span class="avatar avatar-sm">
                                <IconUserCircle></IconUserCircle>
                            </span>
                            <div class="d-none d-xl-block ps-2">
                                <div>{{ username }}</div>
                            </div>
                        </a>
                        <div class="dropdown-menu dropdown-menu-end dropdown-menu-arrow" data-bs-popper="static">
                            <a href="./settings" class="dropdown-item">Settings</a>
                            <a href="./sing-out" class="dropdown-item" @click.prevent="logout">Logout</a>
                        </div>
                    </div>
                </div>
            </div>
        </nav>

        <header class="navbar-expand-md">
            <div class="collapse navbar-collapse" id="navbar-menu">
                <div class="navbar">
                    <div class="container-xl">
                        <div class="row flex-fill align-items-center">
                            <div class="col">
                                <MenuItems></MenuItems>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <div class="container mx-auto my-4 pb-4" style="max-width: 80%;">
            <slot class="container flex-grow-1" ></slot>
        </div>
        <footer class="footer bg-blue text-blue-fg d-print-none">
            <div class="container-xl">
                <div class="row text-center align-items-center flex-row-reverse">
                    <div class="col-lg-auto ms-lg-auto">
                        Build: {{commitHash}}
                    </div>
                    <div class="col-12 col-lg-auto mt-3 mt-lg-0">
                        <ul class="list-inline list-inline-dots mb-0">
                            <li class="list-inline-item">
                                Copyright © 2023
                               CarneCloud
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>


<script>

function decodeJwt(token) {
    try {
        const payloadSegment = token.split('.')[1];
        const base64 = payloadSegment.replace('-', '+').replace('_', '/');
        const payloadJson = atob(base64);
        const payload = JSON.parse(payloadJson);
        return { payload, exp: payload.exp };
    } catch (error) {
        console.error('Failed to decode JWT:', error);
        return null;
    }
}


import {
    IconUserCircle,
    IconMoonFilled,
    IconSunFilled

} from '@tabler/icons-vue';
import MenuItems from "@/components/MenuItems.vue";

export default {
    data() {
        return {
            currentTheme: this.getStoredTheme() || this.getInitialTheme(),
            username: '',
            commitHash: process.env.VUE_APP_COMMIT_HASH
        }
    },
    methods: {
        logout() {
            localStorage.removeItem('token');  // Remove the token from localStorage
            this.redirectToLogin();
        },
        getInitialTheme() {
            return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
        },
        getStoredTheme() {
            return localStorage.getItem('theme');
        },
        toggleTheme() {
            this.currentTheme = this.currentTheme === 'light' ? 'dark' : 'light';
            localStorage.setItem('theme', this.currentTheme);
            document.documentElement.setAttribute('data-bs-theme', this.currentTheme);
        },
        async checkTokenExpiry() {
            const token = localStorage.getItem('token');
            if (token) {
                const { exp } = decodeJwt(token);
                const now = Math.floor(Date.now() / 1000);  // Current time in seconds since epoch
                const timeUntilExpiry = exp - now;  // Time until expiry in seconds

                if (timeUntilExpiry < 300 && timeUntilExpiry > 0) {  // Less than 5 minutes to expiry
                    try {
                        const response = await fetch('/api/auth/renew');
                        const newToken = await response.json();
                        localStorage.setItem('token', newToken.token);
                    } catch (error) {
                        console.error('Failed to renew token:', error);
                        this.redirectToLogin();
                    }
                } else if (timeUntilExpiry <= 0) {  // Token has expired
                    this.redirectToLogin();
                }
            } else {
                this.redirectToLogin();
            }
        },
        redirectToLogin() {
            const currentPath = this.$route.path;
            this.$router.push({ name: 'Login', query: { redirect: currentPath } });
        },
    },
    components: {
        IconUserCircle,
        IconMoonFilled,
        IconSunFilled,
        MenuItems
    },
    mounted() {
        document.documentElement.setAttribute('data-bs-theme', this.currentTheme);
        const token = localStorage.getItem('token');
        if (token) {
            const { payload } =  decodeJwt(token)
            this.username = payload.username;
        }
        this.checkTokenExpiry();
    }
}
</script>

<style scoped>
@media print {
    #basePage {
        display: none !important;
    }
    .container {
        padding: 0 !important;
        max-width: 100% !important;
        width: 100% !important;
    }
}
</style>