<template>
    <div class="modal modal-blur fade" :id="modalId" tabindex="-1" role="dialog" aria-modal="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
            <div class="modal-content">
                <button v-if="!locked"  type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <div class="modal-status bg-danger"></div>
                <div class="modal-body text-center py-4">
                    <div v-if="errorMessage" class="alert alert-danger" role="alert">
                        {{ errorMessage }}
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon mb-2 text-danger icon-lg" width="24" height="24"
                         viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
                         stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M12 9v4"></path>
                        <path d="M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z"></path>
                        <path d="M12 16h.01"></path>
                    </svg>
                    <h3>{{ title }}</h3>
                    <div class="text-secondary">{{ message }}</div>
                </div>
                <div class="modal-footer">
                    <button v-if="!locked" type="button" class="btn me-auto" ref="closeButton" data-bs-dismiss="modal">{{
                        closeButton
                        }}
                    </button>
                    <button type="button" :disabled="locked" class="btn btn-primary" @click="$emit('submit')">{{ submitButton }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        message: {
            type: String,
            default: ''
        },
        errorMessage: {
            type: String,
            default: ''
        },
        modalId: {
            type: String,
            required: true
        },
        title: {
            type: String,
            default: 'Modal Title'
        },
        closeButton: {
            type: String,
            default: 'Close'
        },
        submitButton: {
            type: String,
            default: 'Submit'
        },
        locked: {
            type: Boolean,
            default: false
        }
    }
}
</script>