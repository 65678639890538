<template>
    <div :id="this.id" class="card mt-5 mb-5">
        <div class="card-header">
            <h3 class="card-title">Groups</h3>
            <div class="card-options">
                <button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addGroupModal">Add Group
                </button>
            </div>
        </div>
        <div class="px-3 mt-3">
            <table class="table table-vcenter">
                <thead>
                <tr>
                    <th scope="col">Group ID</th>
                    <th scope="col">Name</th>
                    <th scope="col">Slug</th>
                    <th scope="col" class="w-1 text-end text-nowrap"></th>
                </tr>
                </thead>
                <tbody class="table-tbody">
                <tr v-for="group in groups" :key="group.groupId">
                    <td>{{ group.groupId }}</td>
                    <td>{{ group.groupName }}</td>
                    <td>{{ group.groupSlug }}</td>
                    <td class="text-end text-nowrap">
                        <button class="btn btn-icon btn-ghost-danger" data-bs-toggle="modal"
                                data-bs-target="#deleteGroupModal" @click="askToDeleteGroup(group)">
                            <IconTrash></IconTrash>
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
    <FormModal
            ref="modalComponent"
            modalId="addGroupModal"
            title="Add a new group"
            closeButton="Cancel"
            submitButton="Add Group"
            @submit="handleAddGroupSubmit"
            :errorMessage="errorMessage"
    >
        <AddGroup ref="addGroupComponent"></AddGroup>
    </FormModal>
    <DangerModal
            ref="modalDeleteComponent"
            modalId="deleteGroupModal"
            title="Delete Group"
            :message="deleteGroupMessage"
            closeButton="Cancel"
            submitButton="Delete Group"
            @submit="handleGroupDelete"
            :errorMessage="deleteErrorMessage"
    >
    </DangerModal>
</template>

<script>
import api from '@/services/api';
import FormModal from "@/components/FormModal.vue";
import AddGroup from '@/components/AddGroup.vue';
import {IconTrash} from '@tabler/icons-vue'
import DangerModal from "@/components/DangerModal.vue";

export default {
    props: {
        groupData: {
            type: Array,
            default: null,
        },
        id: {
            type: String,
            default: "Groups"
        }
    },
    data() {
        return {
            groups: [],
            isFormValid: false,
            errorMessage: "",
            deleteGroupMessage: "",
            deleteGroupId: null,
            deleteErrorMessage: null,
        };
    },
    watch: {
        groupData(newVal) {
            if (newVal) {
                this.groups = newVal;
            }
        },
    },
    async created() {
        if (!this.groupData) {
            await this.fetchGroups();
        } else {
            this.groups = this.groupData
        }
    },
    methods: {
        askToDeleteGroup(group) {
            this.deleteGroupMessage = `Are you sure you want to delete group "${group.groupName}"? This could have an impact on products associated with this group.`
            this.deleteGroupId = group.groupId;
        },
        async fetchGroups() {
            try {
                const response = await api.get('/api/groups');
                this.groups = response.data;
            } catch (error) {
                console.error('Error fetching groups:', error);
            }
        },
        async handleGroupDelete() {
            //console.log(`Deleting group: ${this.deleteGroupId}`)
            if (this.deleteGroupId) {
                try {
                    await api.delete(`/api/groups/${this.deleteGroupId}`);
                    this.$emit('group-added')
                    this.$refs.modalDeleteComponent.$refs.closeButton.click();
                    this.deleteGroupId = null;
                    this.deleteErrorMessage = ""
                } catch (error) {
                    console.error('Error deleting group:', error);
                    this.deleteErrorMessage = `Error deleting group: ${error.message}`
                }
            }
        },
        async handleAddGroupSubmit() {
            const addGroupComponent = this.$refs.addGroupComponent;
            const groupData = addGroupComponent.getGroupData();
            if (groupData) {
                try {
                    await api.post('/api/groups', groupData);
                    this.$refs.modalComponent.$refs.closeButton.click();
                    this.$refs.addGroupComponent.reset();
                    this.$emit('group-added')
                    this.errorMessage = ""
                } catch (error) {
                    console.error('Error adding group:', error);
                    this.errorMessage = "Invalid data"
                }
            } else {
                this.errorMessage = "Invalid data"
                console.error('Invalid data');
            }
        },
    },
    components: {
        DangerModal,
        FormModal,
        AddGroup,
        IconTrash
    },
    emits: ['groupAdded'],
};

</script>
