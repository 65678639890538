<template>
    <form @submit.prevent="handleSubmit">
        <div class="card">
            <div class="card-header">
                <h3 v-if="!existingProduct" class="card-title">Add New Product</h3>
                <h3 v-if="existingProduct" class="card-title">{{ product.name }} - #{{ product.productID }}</h3>
                <div v-if="existingProduct" class="card-options">
                    <button v-if="!editMode" class="btn btn-warning" @click="editProduct(true)">Edit</button>
                    <button v-if="editMode" class="btn btn-secondary" @click="editProduct(false)">Cancel</button>
                </div>
            </div>
            <div class="card-body">
                <div class="row mb-3">
                    <!-- Product Name -->
                    <div class="col-md-6">
                        <label class="form-label">Product Name</label>
                        <input v-model="product.name" type="text" class="form-control" required :disabled="!editMode"/>
                    </div>
                    <!-- SKU -->
                    <div class="col-md-6">
                        <label class="form-label">SKU
                            <IconInfoCircle
                                    class="cursor-pointer"
                                    style="margin-top: -9px"
                                    data-bs-toggle="modal"
                                    data-bs-target="#toolTipModal"
                                    @click="toolTipMessage = 'This can only contain lowercase letters and hyphens, this is used for the URL. e.g. https://example.com/shop/:group/my-sku'">
                            </IconInfoCircle>
                        </label>
                        <input v-model="product.sku" type="text" class="form-control" :disabled="!editMode" required/>
                    </div>

                </div>
                <div class="row mb-3">
                    <!-- Category -->
                    <div class="col-md-4">
                        <label class="form-label">Category</label>
                        <div class="d-flex">
                            <select :disabled="!editMode" v-model="product.category"
                                    class="form-select flex-grow-1 me-2" required>
                                <option v-for="category in categories" :key="category.categoryId"
                                        :value="category.categoryId">
                                    {{ category.categoryName }}
                                </option>
                            </select>
                            <div v-if="editMode" class="btn-group" role="group" aria-label="Group actions">
                                <div class="btn btn-icon btn-ghost-primary">
                                    <IconRefresh></IconRefresh>
                                </div>
                                <div class="btn btn-icon btn-ghost-success" data-bs-toggle="modal"
                                     data-bs-target="#addDropdownModal" @click="setupModal('category')">
                                    <IconPlus></IconPlus>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Group -->
                    <div class="col-md-4">
                        <label class="form-label">Group</label>
                        <div class="d-flex">
                            <select :disabled="!editMode" v-model="product.group" class="form-select flex-grow-1 me-2"
                                    required>
                                <option v-for="group in groups" :key="group.groupId" :value="group.groupId">
                                    {{ group.groupName }}
                                </option>
                            </select>
                            <div v-if="editMode" class="btn-group" role="group" aria-label="Group actions">
                                <div class="btn btn-icon btn-ghost-primary">
                                    <IconRefresh></IconRefresh>
                                </div>
                                <div class="btn btn-icon btn-ghost-success" data-bs-toggle="modal"
                                     data-bs-target="#addDropdownModal" @click="setupModal('group')">
                                    <IconPlus></IconPlus>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Type -->
                    <div class="col-md-4">
                        <label class="form-label">Type</label>
                        <div class="d-flex">
                            <select :disabled="!editMode" v-model="product.type" class="form-select flex-grow-1 me-2"
                                    required>
                                <option v-for="type in types" :key="type.typeId" :value="type.typeId">
                                    {{ type.typeName }}
                                </option>
                            </select>
                            <div v-if="editMode" class="btn-group" role="group" aria-label="Group actions">
                                <div class="btn btn-icon btn-ghost-primary">
                                    <IconRefresh></IconRefresh>
                                </div>
                                <div class="btn btn-icon btn-ghost-success" data-bs-toggle="modal"
                                     data-bs-target="#addDropdownModal" @click="setupModal('type')">
                                    <IconPlus></IconPlus>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <!-- Description -->
                <div class="mb-3">
                    <label class="form-label">Description</label>
                    <textarea :disabled="!editMode" v-model="product.description" class="form-control"
                              required></textarea>
                </div>

                <!-- Type -->
                <!--<div class="mb-3">
                    <label class="form-label">Type</label>
                    <input v-model="product.type" type="number" class="form-control" required/>
                </div> -->


                <!-- Allergens -->
                <div class="mb-3">
                    <label class="form-label">Allergens</label>
                    <textarea :disabled="!editMode" v-model="product.allergens" class="form-control"></textarea>
                </div>
                <div class="row mb-3">
                    <div class="col-md-6">
                        <!-- Price -->
                        <label class="form-label">Price (pence)</label>
                        <input :disabled="!editMode" v-model="product.price" type="number" class="form-control"
                               required/>
                    </div>

                    <!-- Weight Unit -->
                    <div class="col-md-6">
                        <label class="form-label">Weight Unit</label>
                        <div class="d-flex">
                            <select :disabled="!editMode" v-model="product.weightUnit" class="form-select" required>
                                <option v-for="unit in weightUnits" :key="unit" :value="unit">
                                    {{ unit }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

                <!-- Status -->
                <div class="mb-3">
                    <label class="form-label">Status
                        <IconInfoCircle
                                class="cursor-pointer"
                                style="margin-top: -9px"
                                data-bs-toggle="modal"
                                data-bs-target="#toolTipModal"
                                @click="toolTipMessage = 'Any temporary status data e.g. Currently not free-range because of bird flue'">
                        </IconInfoCircle>
                    </label>
                    <input :disabled="!editMode" v-model="product.status" type="text" class="form-control"/>
                </div>

                <!-- Quantity -->

                <div class="row mb-3">
                    <div class="col-lg-3 col-sm-12 col-md-6">
                        <label class="form-label">Quantity
                            <IconInfoCircle
                                    class="cursor-pointer"
                                    style="margin-top: -9px"
                                    data-bs-toggle="modal"
                                    data-bs-target="#toolTipModal"
                                    @click="toolTipMessage = 'Number available to be sold, this is only for seasonal produce currently, such as for Christmas. If you want to have an unlimited number, set the value to -1'">
                            </IconInfoCircle>
                        </label>
                        <input :disabled="!editMode" v-model="product.qty" type="number" class="form-control" required/>
                    </div>

                    <!-- Discount Amount -->
                    <div class="col-lg-3 col-sm-12 col-md-6">
                        <label class="form-label">Discount Amount (%)</label>
                        <input :disabled="!editMode" v-model="product.discountAmount" type="number"
                               class="form-control"/>
                    </div>

                    <!-- Discount Quantity -->
                    <div class="col-lg-3 col-sm-12 col-md-6">
                        <label class="form-label">Discount Quantity
                            <IconInfoCircle
                                    class="cursor-pointer"
                                    style="margin-top: -9px"
                                    data-bs-toggle="modal"
                                    data-bs-target="#toolTipModal"
                                    @click="toolTipMessage = 'Minimum required to recieve discount e.g. 10% off when you buy 6 or more'">
                            </IconInfoCircle>
                        </label>
                        <input :disabled="!editMode" v-model="product.discountQty" type="number" class="form-control"/>
                    </div>

                    <!-- Item Deposit -->
                    <div class="col-lg-3 col-sm-12 col-md-6">
                        <label class="form-label">Item Deposit (Pence)
                            <IconInfoCircle
                                    class="cursor-pointer"
                                    style="margin-top: -9px"
                                    data-bs-toggle="modal"
                                    data-bs-target="#toolTipModal"
                                    @click="toolTipMessage = 'Additional deposit for high value seasonal items, e.g. Turkeys'">
                            </IconInfoCircle>
                        </label>
                        <input :disabled="!editMode" v-model="product.itemDeposit" type="number" class="form-control"/>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-md-6">
                        <label class="form-label">Size Guide</label>
                        <select :disabled="!editMode" v-model="product.sizeGuide" class="form-select">
                            <option v-for="size in sizeGuides" :key="size.id" :value="size.id">
                                {{ size.name }}
                            </option>
                        </select>
                    </div>
                    <!-- Seasons -->
                    <div class="col-md-6">
                        <label class="form-label">Seasons</label>
                        <select :disabled="!editMode" v-model="product.seasons" class="form-select">
                            <option v-for="season in seasons" :key="season" :value="season">
                                {{ season }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="row mb-3">
                    <!-- Enabled -->
                    <div class="col-md-6">
                        <div class="form-label">Product State</div>
                        <div class="row mb-2">
                            <label class="form-check form-switch">
                                <input :true-value="1" :false-value="0" :disabled="!editMode" v-model="product.enabled" class="form-check-input"
                                       type="checkbox">
                                <span class="form-check-label">Enabled</span>
                            </label>
                        </div>
                        <div class="row mb-2">
                            <label class="form-check form-switch">

                                <input :true-value="1" :false-value="0" :disabled="!editMode" v-model="product.alwaysOn" class="form-check-input"
                                       type="checkbox">
                                <span class="form-check-label">Always On</span>
                            </label>
                            <IconInfoCircle
                                    class="cursor-pointer"
                                    style="margin-top: -35px; margin-left: 100px; width: 44px"
                                    data-bs-toggle="modal"
                                    data-bs-target="#toolTipModal"
                                    @click="toolTipMessage = 'When enabled, will show in all shops and all seasonal shops'">
                            </IconInfoCircle>

                        </div>
                        <div class="row mb-2">
                            <label class="form-check form-switch">

                                <input :true-value="1" :false-value="0" :disabled="!editMode" v-model="product.featured" class="form-check-input"
                                       type="checkbox">
                                <span class="form-check-label">Featured</span>
                            </label>
                            <IconInfoCircle
                                    class="cursor-pointer"
                                    style="margin-top: -35px; margin-left: 100px; width: 44px"
                                    data-bs-toggle="modal"
                                    data-bs-target="#toolTipModal"
                                    @click="toolTipMessage = 'Show on homepage of shop'">
                            </IconInfoCircle>

                        </div>
                    </div>
                </div>
                <div class="row mt-6">
                    <div class="card">
                        <div class="row row-0">
                            <div class="col-3">
                                <img width="320" height="240" v-if="product.productImage" :src="product.productImage"/>
                                <svg xmlns="http://www.w3.org/2000/svg" v-if="!product.productImage" class="w-100"
                                     preserveAspectRatio="none" width="320" height="240" viewBox="0 0 400 200"
                                     fill="transparent" stroke="var(--tblr-border-color, #b8cef1)">
                                    <line x1="0" y1="0" x2="400" y2="200"></line>
                                    <line x1="0" y1="200" x2="400" y2="0"></line>
                                </svg>
                            </div>
                            <div class="col">
                                <div class="card-body">
                                    <div class="form-label">Product Image</div>
                                    <div class="d-flex">
                                        <input :disabled="!editMode" type="file" class="form-control flex-grow-1 me-2"
                                               @change="onFileChange"/>
                                        <div :disabled="!editMode" class="btn btn-primary" @click="uploadImage">Upload
                                        </div>
                                    </div>
                                    <div v-if="uploadStatus" class="alert alert-azure mt-2">
                                        {{ uploadStatus }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="card-footer text-end">
                <button :disabled="!editMode" type="submit" class="btn btn-primary">Submit</button>
            </div>
        </div>
    </form>
    <div class="card mt-5" v-if="existingProduct">
        <div class="card-header">
            <h3 class="card-title">Weight Options</h3>
            <div class="card-options">
                <button class="btn btn-primary" @click="addRow">Add New Item</button>
            </div>
        </div>
        <div class="card-body">
            <table class="table">
                <thead>
                <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Value</th>
                    <th scope="col">Quantity</th>
                    <th scope="col" class="w-1 text-end text-nowrap"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(option, index) in weightOptions" :key="index">
                    <td v-if="!option.isNew">{{ option.name }}</td>
                    <td v-if="!option.isNew">{{ option.value }}</td>
                    <td v-if="!option.isNew">{{ option.qty }}</td>
                    <td v-if="option.isNew">
                        <input v-model="option.name" placeholder="Name" class="form-control" />
                    </td>
                    <td v-if="option.isNew">
                        <input v-model="option.value" placeholder="Value" class="form-control" />
                    </td>
                    <td v-if="option.isNew">
                        <input type="number" v-model="option.qty" placeholder="Quantity" class="form-control" />
                    </td>
                    <td class="text-end text-nowrap">
                        <button v-if="option.isNew" class="btn btn-icon btn-ghost-success" @click="saveOption(index)">
                            <IconDeviceFloppy></IconDeviceFloppy>
                        </button>
                        <button v-else class="btn btn-icon btn-ghost-danger" data-bs-toggle="modal"
                                data-bs-target="#deleteGroupModal" @click="askToDeleteGroup(option)">
                            <IconTrash></IconTrash>
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
    <CustomisationOptions></CustomisationOptions>

    <DangerModal
        ref="modalDeleteOption"
        modalId="deleteGroupModal"
        title="Delete Weight"
        :message="deleteMessage"
        closeButton="Cancel"
        submitButton="Delete"
        @submit="handleOptionDelete"
        :errorMessage="deleteErrorMessage"
    ></DangerModal>
    <InfoModal modal-id="toolTipModal" :message="toolTipMessage"></InfoModal>
    <FormModal
            ref="modalComponent"
            modalId="addDropdownModal"
            :title="addModalTitle"
            closeButton="Cancel"
            submitButton="Add"
            @submit="handleAddSubmit"
            :errorMessage="modalErrorMessage"
    >
        <AddCategory v-if="addModal === 'category'" ref="addCategoryComponent"></AddCategory>
        <AddGroup v-if="addModal === 'group'" ref="addGroupComponent"></AddGroup>
    </FormModal>

</template>

<script>
import {IconRefresh, IconPlus, IconInfoCircle, IconDeviceFloppy, IconTrash} from "@tabler/icons-vue";
import api from '@/services/api';
import FormModal from "@/components/FormModal.vue";
import AddCategory from "@/components/AddCategory.vue";
import AddGroup from "@/components/AddGroup.vue";
import InfoModal from "@/components/InfoModal.vue";
import CustomisationOptions from "@/components/CustomisationOptions.vue";
import DangerModal from "@/components/DangerModal.vue";

export default {
    name: 'ProductView',
    data() {
        return {
            product: {
                name: '',
                sku: '',
                category: null,
                description: '',
                type: 0,
                group: null,
                allergens: '',
                price: null,
                weightUnit: null,
                status: '',
                qty: null,
                discountAmount: "0",
                discountQty: "0",
                itemDeposit: "0",
                sizeGuide: "-1",
                enabled: false,
                seasons: "",
                productImage: "",
                alwaysOn: false,
                featured: false,
            },
            editMode: true,
            existingProduct: false,
            weightOptions: [],
            productId: null,
            selectedFile: null,
            uploadStatus: '',
            toolTipMessage: '',
            addModal: '',
            deleteMessage: '',
            deleteErrorMessage: '',
            weightToDelete: null,
            modalErrorMessage: '',
            addModalTitle: '',
            categories: [],
            groups: [],
            types: [],
            weightUnits: ["kg", "each"],
            sizeGuides: [{name: "none", id: -1}],  // Assume these come from an API
            seasons: []  // Assume these come from an API
        };
    },
    async mounted() {

        if (this.$route.params.productId) {
            this.existingProduct = true
            this.productId = this.$route.params.productId
            this.editMode = false;
            await this.getExistingProduct();
        } else {
            document.title = "Add New Product | CarneCloud"
        }
        await this.fetchData();
    },
    methods: {
        editProduct(a) {
            this.editMode = a;
        },
        onFileChange(event) {
            this.selectedFile = event.target.files[0];
        },
        addRow() {
            console.log("add new row")
            this.weightOptions.unshift({
                weightOptionId: null,  // this will be a new unsaved item
                productId: parseInt(this.productId),  // assuming you have the current product ID available
                name: '',  // empty string as a placeholder
                value: '',  // empty string as a placeholder
                qty: 0,  // default to zero
                isNew: true  // flag to show save icon
            });
            console.log(this.weightOptions)
            this.$forceUpdate();
        },
        async saveOption(index) {
            //console.log(index)
            if(this.weightOptions[index].name, this.weightOptions[index].value, this.weightOptions[index].qty) {
                const postWeight = await this.postWeightOption({
                    productId: this.productId,
                    name: this.weightOptions[index].name,
                    value: this.weightOptions[index].value,
                    qty: this.weightOptions[index].qty,
                })
                if(postWeight) {
                    await this.getWeightOptions()
                }
            }
            // Logic to save new option
            // On successful save, set `isNew` to false
        },
        async postWeightOption(weightOption) {
            try {
                const response = await api.post('/api/products/weight-option', {
                    productId: weightOption.productId,
                    name: weightOption.name,
                    value: weightOption.value,
                    qty: weightOption.qty
                });
                console.log('Weight option added:', response.data);
                return true
            } catch (error) {
                console.error('Error posting weight option:', error);
                return false
                // handle error accordingly, e.g., show a user-friendly error message
            }
        },
        askToDeleteGroup(option) {
            this.deleteMessage = `Are you sure you want to delete weight ${option.name}?`
            this.weightToDelete = option.weightOptionId
        },
        async handleOptionDelete() {
            if(this.weightToDelete >= 1) {
                try {
                    await api.delete("/api/products/weight-option/"+this.weightToDelete)
                    await this.getWeightOptions();
                    this.$refs.modalDeleteOption.$refs.closeButton.click()
                    this.weightToDelete = 0;
                    this.deleteMessage = ''
                        this.deleteErrorMessage = ''
                } catch(e) {
                    this.deleteErrorMessage = e.message;
                }
            }
        },
        async uploadImage() {
            if (!this.selectedFile) {
                this.uploadStatus = 'No file selected';
                return;
            }

            const formData = new FormData();
            formData.append('image', this.selectedFile);
            try {
                const response = await api.post('/api/dam/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                this.uploadStatus = 'Upload successful: ' + response.data.url;
                this.product.productImage = response.data.url;
            } catch (error) {
                this.uploadStatus = 'Upload failed: ' + error.response.data.error;
            }
        },
        async getWeightOptions() {
            const weightOpts = await api.get('/api/products/' + this.productId + "/weight-options")
            this.weightOptions = weightOpts.data;
        },
        async getExistingProduct() {
            const [resp, weightOpts] = await Promise.all([
                api.get('/api/products/' + this.productId),
                api.get('/api/products/' + this.productId + "/weight-options"),
            ])
            this.weightOptions = weightOpts.data;
            this.product = resp.data;
            document.title = `${this.product.name} | CarneCloud`
        },
        async fetchData() {
            try {
                const [groupsRes, categoriesRes, typesRes] = await Promise.all([
                    api.get('/api/groups'),
                    api.get('/api/categories'),
                    api.get('/api/types'),
                ]);
                this.groups = groupsRes.data;
                this.categories = categoriesRes.data;
                this.types = typesRes.data;
                return true
            } catch (error) {
                console.error('Error fetching data:', error);
                return false
            }
        },
        async handleAddSubmit() {

        },
        setupModal(modalType) {
            if (modalType === "category") {
                this.addModal = modalType;
                this.addModalTitle = "Add new category"
                this.modalErrorMessage = ""
            } else if (modalType === "group") {
                this.addModal = modalType;
                this.addModalTitle = "Add new group"
                this.modalErrorMessage = ""
            } else {
                this.addModal = "";
                this.addModalTitle = "Error"
                this.modalErrorMessage = "Error unknown type"
            }
        },
        async handleSubmit() {
            if (this.existingProduct) {
                if (this.editMode) {
                    try {
                        await api.put('/api/products/' + this.productId, this.product);
                        this.editMode = false;
                        await this.getExistingProduct();
                    } catch (error) {
                        console.error('Error updating product:', error.response ? error.response.data : error.message);
                    }
                }
            } else {
                try {
                    const response = await api.post('/api/products', this.product);
                    // Handle success - response.data will contain the server's response
                    console.log('Product created with ID:', response.data.insertedId);
                    this.$router.push('/pim/');
                } catch (error) {
                    // Handle error - error.response.data will contain the server error message, if available
                    //console.error('Error creating product:', error.response ? error.response.data : error.message);
                    console.error('Error creating product:', error.response ? error.response.data : error.message);
                }
            }
        },
    }
    ,
    components: {
        DangerModal,
        CustomisationOptions,
        InfoModal,
        AddCategory, FormModal, AddGroup,
        IconRefresh,
        IconPlus,
        IconInfoCircle,
        IconDeviceFloppy,
        IconTrash
    }
}
;
</script>