<template>
    <BasePage>
        <div class="container">
            <div class="container">
                <div class="card mb-6 mt-6" v-for="order in orders" :key="order.id">
                    <div class="card-header" :class="{'bg-danger': order.depositStatus === 'cancelled'}">
                        <h3 class="card-title">Order N<sup>o</sup> {{ order.id }}</h3>
                        <div class="card-options gap-2">
                            <div v-if="order.depositStatus === 'paid' && !isEditMode" @click="toggleEditMode(order)"
                                 class="btn btn-primary">Edit Order
                            </div>
                            <div v-if="order.depositStatus === 'paid' && !isEditMode" data-bs-toggle="modal"
                                 data-bs-target="#resendModal" class="btn btn-primary">Resend Confirmation
                            </div>
                            <div v-if="order.depositStatus === 'paid' && !isEditMode" data-bs-toggle="modal"
                                 data-bs-target="#paymentModal" class="btn btn-primary">Send Payment Email
                            </div>
                            <div v-if="order.depositStatus === 'paid' && !isEditMode" data-bs-toggle="modal"
                                 data-bs-target="#cancelOrderModal" class="btn btn-danger">Cancel Order
                            </div>
                            <div v-if="isEditMode" @click="cancelEditMode" class="btn btn-secondary">Cancel</div>
                            <div v-if="isEditMode" @click="saveOrderChanges" class="btn btn-primary">Save</div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div v-if="order.collected" class="alert-success alert alert-important">
                            Order Collected
                        </div>
                        <div class="datagrid">
                            <div class="datagrid-item">
                                <div class="datagrid-title">Name</div>
                                <div v-if="!isEditMode" class="datagrid-content">{{ order.firstName }} {{
                                    order.lastName
                                    }}
                                </div>
                                <input v-if="isEditMode" type="text" class="form-control"
                                       v-model="editableOrder.firstName"/>
                                <input v-if="isEditMode" type="text" class="form-control"
                                       v-model="editableOrder.lastName"/>
                            </div>
                            <div class="datagrid-item">
                                <div class="datagrid-title">Email</div>
                                <div v-if="!isEditMode" class="datagrid-content">{{ order.email }}</div>
                                <input v-if="isEditMode" type="text" class="form-control" v-model="editableOrder.email">
                            </div>
                            <div class="datagrid-item">
                                <div class="datagrid-title">Phone Number</div>
                                <div v-if="!isEditMode" class="datagrid-content">{{ order.phoneNumber }}</div>
                                <input v-if="isEditMode" type="text" class="form-control"
                                       v-model="editableOrder.phoneNumber">
                            </div>
                            <div class="datagrid-item">
                                <div class="datagrid-title">Deposit Status</div>
                                <div class="datagrid-content"
                                     :class="{'text-danger': order.depositStatus === 'cancelled'}">
                                    {{ order.depositStatus }}
                                </div>
                            </div>
                            <div class="datagrid-item">
                                <div class="datagrid-title">Deposit Amount</div>
                                <div class="datagrid-content">{{ formatCurrency(order.depositAmount) }}</div>
                            </div>
                            <div class="datagrid-item">
                                <div class="datagrid-title">Stripe Amount</div>
                                <div class="datagrid-content">{{ formatCurrency(order.stripeTotal) }}</div>
                            </div>
                            <div class="datagrid-item">
                                <div class="datagrid-title">Collection Method</div>
                                <div class="datagrid-content">{{ order.collectionOption }}</div>
                            </div>
                            <div class="datagrid-item" v-if="order.collectionOption === 'Collection'">
                                <div class="datagrid-title">Collection Location</div>
                                <div class="datagrid-content">{{ order.collectionLocation }}</div>
                            </div>
                            <div class="datagrid-item" v-if="order.collectionOption === 'Collection'">
                                <div class="datagrid-title">Collection Date</div>
                                <div class="datagrid-content">{{ order.collectionDate }}</div>
                            </div>
                            <div class="datagrid-item" v-if="order.collectionOption === 'Collection'">
                                <div class="datagrid-title">Collection Time</div>
                                <div class="datagrid-content">{{ order.collectionTime }}</div>
                            </div>
                            <div class="datagrid-item" v-if="order.collectionOption === 'Delivery'">
                                <div class="datagrid-title">Delivery Date</div>
                                <div class="datagrid-content">{{ order.deliveryDate }}</div>
                            </div>
                            <div class="datagrid-item" v-if="order.collectionOption === 'Delivery'">
                                <div class="datagrid-title">Delivery Address</div>
                                <div class="datagrid-content">{{ order.deliveryAddress }}</div>
                            </div>
                            <div class="datagrid-item">
                                <div class="datagrid-title">Confirmation Email Sent</div>
                                <div class="datagrid-content">{{ Boolean(order.confirmationEmailSent) }}</div>
                            </div>
                            <div class="datagrid-item">
                                <div class="datagrid-title">Payment Email Sent</div>
                                <div class="datagrid-content">{{ Boolean(order.paymentEmailSent) }}</div>
                            </div>
                            <div class="datagrid-item">
                                <div class="datagrid-title">Payment Email Sent</div>
                                <div class="datagrid-content">{{ Boolean(order.reminderEmailSent) }}</div>
                            </div>
                            <div class="datagrid-item" v-if="order.balanceStatus">
                                <div class="datagrid-title">Balance Status</div>
                                <div class="datagrid-content">{{ order.balanceStatus }}</div>
                            </div>



                        </div>
                        <h2 class="h2 mt-6">Order Items</h2>
                        <table class="table card-table table-vcenter mt-2 mb-6">
                            <thead>
                            <tr>
                                <th>Item</th>
                                <th>Size</th>
                                <th>Allocated</th>
                                <th>Extra Info</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="item in order.items" :key="item.id">
                                <td>{{ item.item }}</td>
                                <td>{{ item.size }} <span v-if="item.unit === 'kg'">kg</span></td>
                                <td>
                                    <span v-if="item.unit === 'kg'">{{item.allocatedWeight}} kg</span>
                                    <span v-if="item.unit === 'each'">{{item.size}}</span>
                                </td>
                                <td>{{ customisations(item.customisationsData) }} / {{item.extraInfo}}</td>
                            </tr>
                            </tbody>
                        </table>
                        <h2 class="h2 mt-6">Stripe Checkout</h2>
                        <table class="table card-table table-vcenter mt-2 mb-6">
                            <thead>
                            <tr>
                                <th>Item</th>
                                <th>Desc</th>
                                <th>Price</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="item in stripeItems" :key="item.price_data.product_data.name">
                                <td>{{ item.price_data.product_data.name }}</td>
                                <td>{{ item.price_data.product_data.description }}</td>
                                <td>{{ formatCurrency(item.price_data.unit_amount) }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="card-body">
                        <div class="card mt-3">
                            <div class="card-header">
                                <h3 class="h2 card-title">Order Comments</h3>
                                <div class="card-options">
                                    <div v-if="!addingComment" @click="addingComment = true"
                                         class="btn-outline-azure btn btn-sm">
                                        <IconPlus></IconPlus>
                                    </div>
                                    <div v-if="addingComment" @click="addingComment = false; orderComment = ''"
                                         class="btn-outline-azure btn btn-sm">
                                        <IconX></IconX>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <div v-if="addingComment" class="card mt-2 mb-6">
                                    <div class="card-body">
                                        <h2>New Comment</h2>
                                        <textarea class="form-control" v-model="orderComment"></textarea>
                                    </div>
                                    <div class="card-footer">
                                        <div @click="submitComment" class="btn btn-primary cursor-pointer">Save</div>
                                    </div>
                                </div>
                                <textarea disabled class="form-control" v-model="order.staffComments"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <danger-modal modal-id="resendModal" ref="resendConfModal" title="Resend Confirmation"
                      closeButton="Cancel"
                      submitButton="Send"
                      message="Are you sure you want to resend the confirmation email?"
                      @submit="resendConfirmationEmail"
                      :errorMessage="errorMessage">

        </danger-modal>
        <danger-modal modal-id="paymentModal" ref="paymentModal" title="Payment Email Confirmation"
                      closeButton="Cancel"
                      submitButton="Send"
                      message="Are you sure you want to send a payment email?"
                      @submit="sendPaymentEmail"
                      :errorMessage="errorMessage">

        </danger-modal>

        <danger-modal modal-id="cancelOrderModal" ref="cancelModal" title="Cancel Order"
                      closeButton="Exit"
                      submitButton="Cancel Order"
                      message="Are you sure you want to cancel this order?"
                      @submit="cancelOrder"
                      :errorMessage="errorMessage">

        </danger-modal>
    </BasePage>
</template>

<script>


import api from "@/services/api";


import BasePage from '@/components/BasePage.vue';
import {IconPlus, IconX} from '@tabler/icons-vue'
import DangerModal from "@/components/DangerModal.vue";

export default {
    name: 'HomePage',
    components: {
        DangerModal,
        BasePage,
        IconPlus,
        IconX
    },
    data() {
        return {
            orders: [],
            stripeItems: [],
            addingComment: false,
            orderComment: "",
            errorMessage: "",
            isEditMode: false,
            editableOrder: null,
        }
    },
    methods: {
        async submitComment() {
            let spacer = "";
            let existingComments = ""
            if (this.orders[0].staffComments) {
                spacer = "\n------\n"
                existingComments = this.orders[0].staffComments
            }
            const updatedMessage = this.orderComment + spacer + existingComments
            await api.put(`/api/orders/${this.$route.params.orderId}/comment`, {staffComments: updatedMessage})
            this.orderComment = ""
            this.addingComment = false;
            await this.getOrders();
        },
        async sendPaymentEmail() {
            await api.post(`/api//billing/send-email/${this.orders[0].orderGUID}`)
            //this.orderComment = "Confirmation resent at " + new Date();
            //await this.submitComment();
            this.$refs.paymentModal.$refs.closeButton.click();
        },
        async cancelOrder() {
            console.log(this.orders[0].id, this.orders[0].orderGUID)
            try {
                await api.delete(`/api/orders/${this.orders[0].id}/${this.orders[0].orderGUID}`)
                await this.getOrders();
                this.$refs.cancelModal.$refs.closeButton.click();
            } catch (e) {
                this.errorMessage = e.message
            }
        },
        customisations(custs) {
            let extraInfoItems = []
            for (const inf of JSON.parse(custs)) {
                extraInfoItems.push(`${inf.name}: ${inf.text}`)
            }
            return extraInfoItems.join(" | ")
        },
        formatCurrency(amountInPence) {
            const pounds = amountInPence / 100;
            return new Intl.NumberFormat('en-GB', {style: 'currency', currency: 'GBP'}).format(pounds);
        },
        async getOrders() {
            try {
                console.log(this.$route.params.orderId)
                const response = await api.get('/api/orders/' + this.$route.params.orderId);
                this.orders = response.data;
                this.stripeItems = JSON.parse(response.data[0].stripeItems)

            } catch (e) {
                console.log(e)
            }
        },
        toggleEditMode(order) {
            this.isEditMode = true;
            this.editableOrder = {...order}; // Make a clone of the order to edit
        },
        cancelEditMode() {
            this.isEditMode = false;
            this.editableOrder = null;
        },
        async saveOrderChanges() {
            // Validate your `editableOrder` data here
            try {
                await api.put(`/api/orders/${this.editableOrder.id}`, this.editableOrder);
                this.isEditMode = false;
                this.orderComment = "Order details updated manually at " + new Date();
                await this.submitComment();
                await this.getOrders(); // Refresh the orders to get the updated data
            } catch (e) {
                // Handle errors, e.g., show an error message
                console.error(e);
            }
        },
        async resendConfirmationEmail() {
            try {
                await api.post(`/api/resend-confirmation-email`, {guid: this.orders[0].orderGUID})
                this.orderComment = "Confirmation resent at " + new Date();
                await this.submitComment();
                this.$refs.resendConfModal.$refs.closeButton.click();
            } catch (e) {
                console.log(e)
            }
        }
    },
    async mounted() {
        await this.getOrders()
        document.title = "My Order | Larkhall Butchers"
    }
};
</script>
