<template>
    <div :id="this.id" class="card mt-5">
        <div class="card-header">
            <h3 class="card-title">Categories</h3>
            <div class="card-options">
                <button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addGroupModal">Add Category</button>
            </div>
        </div>
        <div class="px-3 mt-3">
            <table class="table table-vcenter">
                <thead>
                <tr>
                    <th scope="col">Category ID</th>
                    <th scope="col">Name</th>
                    <th scope="col">Slug</th>
                    <th scope="col" class="w-1 text-end text-nowrap"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="category in categories" :key="category.categoryId">
                    <td>{{ category.categoryId }}</td>
                    <td>{{ category.categoryName }}</td>
                    <td>{{ category.categorySlug }}</td>
                    <td class="text-end text-nowrap">
                        <button class="btn btn-icon btn-ghost-danger" data-bs-toggle="modal"
                                data-bs-target="#deleteGroupModal" @click="askToDeleteGroup(category)">
                            <IconTrash></IconTrash>
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
    <FormModal
        ref="modalComponent"
        modalId="addGroupModal"
        title="Add a new category"
        closeButton="Cancel"
        submitButton="Add Category"
        @submit="handleAddGroupSubmit"
        :errorMessage="errorMessage"
    >
        <AddCategory ref="addGroupComponent"></AddCategory>
    </FormModal>
    <DangerModal
        ref="modalDeleteComponent"
        modalId="deleteGroupModal"
        title="Delete Category"
        :message="deleteGroupMessage"
        closeButton="Cancel"
        submitButton="Delete Category"
        @submit="handleGroupDelete"
        :errorMessage="deleteErrorMessage"
    >
    </DangerModal>
</template>

<script>
import api from '@/services/api';
import FormModal from "@/components/FormModal.vue";
import DangerModal from "@/components/DangerModal.vue";
import AddCategory from "@/components/AddCategory.vue";
import {IconTrash} from "@tabler/icons-vue";

export default {
    components: {IconTrash, AddCategory, DangerModal, FormModal},
    props: {
        categoryData: {
            type: Array,
            default: null,
        },
        id: {
            type: String,
            default: "Categories"
        }
    },
    data() {
        return {
            categories: [],
            errorMessage: "",
            deleteGroupMessage: "",
            deleteGroupId: null,
            deleteErrorMessage: null,
        };
    },
    watch: {
        categoryData(newVal) {
            if (newVal) {
                this.categories = newVal;
            }
        },
    },
    async created() {
        if (!this.categoryData) {
            await this.fetchCategories();
        } else {
            this.categories = this.categoryData
        }
    },
    methods: {
        askToDeleteGroup(category) {
            this.deleteGroupMessage = `Are you sure you want to delete category "${category.groupName}"? This could have an impact on products associated with this category.`
            this.deleteGroupId = category.categoryId;
        },
        async fetchCategories() {
            try {
                const response = await api.get('/api/categories');
                this.categories = response.data;
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        },
        async handleGroupDelete() {
            //console.log(`Deleting group: ${this.deleteGroupId}`)
            if (this.deleteGroupId) {
                try {
                    await api.delete(`/api/categories/${this.deleteGroupId}`);
                    this.$emit('group-added')
                    this.$refs.modalDeleteComponent.$refs.closeButton.click();
                    this.deleteGroupId = null;
                    this.deleteErrorMessage = ""
                } catch (error) {
                    console.error('Error deleting category:', error);
                    this.deleteErrorMessage = `Error deleting category: ${error.message}`
                }
            }
        },
        async handleAddGroupSubmit() {
            const addGroupComponent = this.$refs.addGroupComponent;
            const groupData = addGroupComponent.getGroupData();
            console.log(groupData)
            if (groupData) {
                try {
                    await api.post('/api/categories', groupData);
                    this.$refs.modalComponent.$refs.closeButton.click();
                    this.$refs.addGroupComponent.reset();
                    this.$emit('group-added')
                    this.errorMessage = ""
                } catch (error) {
                    console.error('Error adding category:', error);
                    this.errorMessage = "Invalid data"
                }
            } else {
                this.errorMessage = "Invalid data"
                console.error('Invalid data');
            }
        },
    },
};

</script>

<style scoped>
/* You may add custom styling if needed */
</style>
