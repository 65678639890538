<template>
    <div class="card mb-3">
        <div class="card-header">
            <h3 class="card-title">Orders Overview</h3>
        </div>
        <div class="card-body">
            <div class="datagrid">
                <div class="datagrid-item" v-for="orderData in ordersOverview" v-bind:key="orderData">
                    <div class="datagrid-title">{{ orderData.title }}</div>
                    <div class="datagrid-content">{{ orderData.value }}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "OrdersOverview",
    props: {
        orders: {
            type: Array,
        }
    },
    data() {
        return {
            ordersOverview: []
        }
    },
    async mounted() {
        this.updateOverview()
    },
    methods: {
        updateOverview() {
            this.ordersOverview = []
            this.ordersOverview.push({title: "Total Orders", value: this.orders.length})
            console.log(this.orders.filter(o => o.collectionOption === 'Delivery'))
            this.ordersOverview.push({
                title: "Delivery Orders",
                value: (this.orders.filter(o => o.collectionOption === 'Delivery')).length
            })
            this.ordersOverview.push({
                title: "Collection Orders",
                value: (this.orders.filter(o => o.collectionOption === 'Collection')).length
            })
            const locationCounts = this.orders.reduce((acc, order) => {
                if (order.collectionOption === 'Collection') {
                    const location = order.collectionLocation;
                    acc[location] = (acc[location] || 0) + 1;
                }
                return acc;
            }, {});


            for (const [location, count] of Object.entries(locationCounts)) {
                this.ordersOverview.push({title: `Collection Location: ${location}`, value: count});
            }
            const collectedAmount = (this.orders.filter(o => o.collected == true)).length
            this.ordersOverview.push({title: "Amount Collected", value: `${collectedAmount}/${this.orders.length} (${Math.round(collectedAmount/this.orders.length * 100)}%)`})
        }
    },
    watch: {
        orders(newVal) {
            if (newVal) {
                this.updateOverview()
            }
        },
    }
}
</script>