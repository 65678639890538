<template>
    <div v-for="p in pages" :key="p" class="a4-sheet">
        <div v-for="l in p" :key="l" class="label">
            <div class="row gap-2">
                <div class="col"><span class="h1">{{ l.id }}</span><br/><span style="font-size: 8pt">{{ l.firstName }} {{
                    l.lastName
                    }}</span></div>
                <div class="col">
                    <img :src="'https://qrcode-service.larkhall-butchers.workers.dev/'+l.id"/>
                </div>
                <div class="col">
                    <span class="h1">{{ formatOrdinal(l.collectionDate) }} {{
                        formatOrdinal(l.deliveryDate)
                        }}</span><br/>
                    <small v-if="l.collectionOption === 'Collection'" :style="bgColor(l.collectionLocation.split(' ')[0])"
                           class="p-1">{{ l.collectionLocation.split(" ")[0] }}</small>
                    <small v-if="l.collectionOption === 'Delivery'"
                           class="bg-danger p-1 text-danger-fg">Delivery</small>
                </div>
            </div>
            <div class="row align-items-center align-content-center align-center justify-content-center text-center">
                <div class="h3">{{ l.item }}</div>
                <div class="h2">{{ l.size }} <span v-if="l.unit === 'kg'">KG</span></div>
            </div>
            <div class="row border-top mt-2">
                <div :class="{ extraInfo: ((l.sku === 'turkey-crown-bacon' && l.extraInfo !== 'bacon-lattice | stuffing-sausage-meat ') || (l.extraInfo !== '' && l.sku !== 'turkey-crown-bacon'))}" class="text-center mt-2" v-if="l.extraInfo !== ' | '">{{ l.extraInfo }}</div>
            </div>
        </div>
    </div>
</template>
<script>
import api from '@/services/api'

export default {
    name: "LabelPage",
    data() {
        return {
            pages: []
        }
    },
    async mounted() {
        let res = null;
        if(this.$route.params.filter) {
            res = await api.get(`/api/label/${this.$route.params.sku}/${this.$route.params.filter}`);
        } else {
            res = await api.get('/api/label/'+this.$route.params.sku);
        }
        this.pages = []
        for (let i = 0; i < res.data.length; i += 12) {
            this.pages.push(res.data.slice(i, i + 12));
        }
        //console.log(res);
    },
    methods: {
        formatOrdinal(str) {
            // Remove non-numeric characters from the string
            try {
                let number = str.replace(/\D/g, '');

                // Convert the cleaned string to a number
                number = parseInt(number, 10);

                // Check if the number is valid
                if (isNaN(number)) {
                    //throw new Error('No valid number found in string');
                    return ""
                }

                // Determine the ordinal suffix
                let suffix;
                if (number % 10 === 1 && number % 100 !== 11) {
                    suffix = 'st';
                } else if (number % 10 === 2 && number % 100 !== 12) {
                    suffix = 'nd';
                } else if (number % 10 === 3 && number % 100 !== 13) {
                    suffix = 'rd';
                } else {
                    suffix = 'th';
                }

                // Combine the number and the ordinal suffix
                return number + suffix;

            } catch (e) {
                console.log(e)
                return ""
            }
        },
        stringToColour(str) {
            let hash = 0;
            for (let i = 0; i < str.length; i++) {
                hash = hash * 31 + str.charCodeAt(i);
                hash |= 0;  // Convert to 32-bit integer
            }
            let colour = '#';
            for (let i = 0; i < 3; i++) {
                const value = (hash >> (i * 8)) & 0xFF;
                colour += ('00' + value.toString(16)).substr(-2);
            }
            return colour;
        },
        getLuminance(hexColor) {
            const r = parseInt(hexColor.slice(1, 3), 16) / 255;
            const g = parseInt(hexColor.slice(3, 5), 16) / 255;
            const b = parseInt(hexColor.slice(5, 7), 16) / 255;
            return (0.299 * r + 0.587 * g + 0.114 * b);
        },
        bgColor(str) {
            const backgroundColor = this.stringToColour(str);
            // Using a luminance threshold of 0.5 to decide on text color.
            const textColor = this.getLuminance(backgroundColor) > 0.5 ? 'black' : 'white';
            return {
                backgroundColor,
                color: textColor,
            };
        }
    }
}
</script>
<style scoped>
* {
    background: white;
}
.extraInfo {
    background: #C3984A;
}

/* Define the A4 size and setup margins */
.a4-sheet {
    width: 210mm;
    height: 297mm;
    padding: 4.5mm 7mm;
    box-sizing: border-box;
    font-family: Arial, sans-serif;

}

/* Define the grid and label styling */
.label {
    width: 63.5mm;
    height: 72mm;
    display: inline-block;
    box-sizing: border-box;
    margin-right: 2mm;
    margin-bottom: 0mm;
    margin-top: 0mm;
    border-radius: 1.5mm;
    vertical-align: top;
    border: 1pt solid black;
    padding: 2mm;
    overflow: hidden;

}

/* Remove right margin for every third label */
.label:nth-child(3n) {
    margin-right: 0mm; /* Width of label + gap */
}

@media print {
    /* This will remove the background color when printed */
    .label {
        background: none;
    }
    .extraInfo {
        background: #C3984A;
    }

    /* This ensures the entire sheet is scaled to fit on one A4 page */
    body, html {
        height: 100%;
        width: 100%;
        margin: 0;
        padding: 0;
    }

    .a4-sheet {
        box-sizing: border-box;
        height: 297mm; /* A4 height */
        width: 210mm;

        /* A4 width */
    }

    @page {
        margin: 0;
    }

    .label {

    }
}
</style>