<template>
    <BasePage>
        <div class="container">
            <div id="reportsMenu" class="card mt-6 mb-6">
                <div class="row g-0">
                    <div class="col-12 col-md-2 border-end border-1 border-start">
                        <div class="card-body">
                            <h4 class="subheader">Reports</h4>
                            <div class="list-group list-group-transparent">
                                <a href="./collection" class="list-group-item list-group-item-action d-flex align-items-center" :class="{active: isActive('collection')}">Collection</a>
                                <a href="./delivery" class="list-group-item list-group-item-action d-flex align-items-center" :class="{active: isActive('delivery')}">Delivery</a>
                                <a href="./products" class="list-group-item list-group-item-action d-flex align-items-center" :class="{active: isActive('products')}">Products</a>
                                <a href="./full" class="list-group-item list-group-item-action d-flex align-items-center" :class="{active: isActive('full')}">Full Reports</a>
                                <a href="./labels" class="list-group-item list-group-item-action d-flex align-items-center" :class="{active: isActive('labels')}">Labels</a>
                                <!--<a href="./works-order" class="list-group-item list-group-item-action d-flex align-items-center" :class="{active: isActive('works-order')}">Works Orders</a>-->
                                <a href="./collection-slips" class="list-group-item list-group-item-action d-flex align-items-center" :class="{active: isActive('collection-slips')}">Collection Slips</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-1 border-start h-100"></div>
                    <div class="col-12 col-md-9 px-2">
                        <CollectionReport v-if="this.page === 'collection'" :collections="collections"></CollectionReport>
                        <DeliveryReport v-if="this.page === 'delivery'" :deliveries="deliveries"></DeliveryReport>
                        <ProductReport v-if="this.page === 'products'" :orderedProducts="orderedProducts" :products="products"></ProductReport>
                        <FullReport ref="fullReport" v-if="this.page === 'full'"></FullReport>
                        <LabelBuilder ref="lablesBuilder" v-if="this.page === 'labels'"></LabelBuilder>
                        <SlipReport ref="slipReport" v-if="this.page === 'collection-slips'"></SlipReport>
                    </div>
                </div>
            </div>
            <div v-if="this.page === 'full'" class="card">
                <div class="card-header">
                    <div class="card-title">
                        Full Report
                    </div>
                    <div class="card-options">
                        <div class="" style="margin-right: 20px;">
                        <label class="form-check form-switch mt-2">
                            <input class="form-check-input" type="checkbox" v-model="excludeCollected">
                            <span class="form-check-label">Exclude Collected</span>
                        </label>
                        </div>
                        <button id="runReportButton" @click="runReport" class="btn btn-sm btn-primary">Run Report</button>
                    </div>
                </div>
                <div class="card-body">
                    <table class="table table-bordered">
                        <thead>
                        <tr>
                            <th>Type Name</th>
                            <th>Product</th>
                            <th>Size</th>
                            <th>Customisation</th>
                            <th>Count</th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="category in reportData">
                            <template v-for="(product) in category.products">
                                <!-- Calculate total rows for rowspan -->
                                <template v-if="product.unit !== 'each'">
                                    <!-- Calculate total rows for this product -->
                                    <template v-for="(size, sizeIndex) in product.sizes" :key="`${product.sku}-${size.size}`">
                                        <tr>
                                            <td v-if="sizeIndex === 0" :rowspan="product.sizes.reduce((sum, size) => sum + (size.customisations && size.customisations.length > 0 ? size.customisations.length + 1 : 1), 0)">{{ category.typeName }}</td>
                                            <td v-if="sizeIndex === 0" :rowspan="product.sizes.reduce((sum, size) => sum + (size.customisations && size.customisations.length > 0 ? size.customisations.length + 1 : 1), 0)">{{ product.product }}</td>
                                            <td>{{ size.size }} {{ product.unit }}</td>
                                            <td>Total</td>
                                            <td>{{ size.count }}</td>
                                        </tr>
                                        <template :key="`${product.sku}-${size.size}-custom-${customIndex}`" v-for="(customisation, customIndex) in size.customisations">
                                            <tr v-if="size.customisations && size.customisations.length > 0">
                                                <td></td>
                                                <td>{{ customisation.customisation }}</td>
                                                <td>{{ customisation.count }}</td>
                                            </tr>
                                        </template>
                                    </template>
                                </template>
                                <!-- Handle 'each' unit -->
                                <tr v-else :key="product.sku">
                                    <td>{{ category.typeName }}</td>
                                    <td>{{ product.product }}</td>
                                    <td></td>
                                    <td>Total</td>
                                    <td>{{ product.total }}</td>
                                </tr>
                            </template>
                        </template>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    </BasePage>
</template>
<script>
import ProductReport from "@/components/reports/ProductReport.vue";
import FullReport from "@/components/reports/FullReport.vue";

import api from '@/services/api'
import BasePage from '@/components/BasePage.vue';
import DeliveryReport from "@/components/reports/DeliveryReport.vue";
import CollectionReport from "@/components/reports/CollectionReport.vue";
import fullReport from "@/components/reports/FullReport.vue";
import LabelBuilder from "@/components/reports/LabelBuilder.vue";
import SlipReport from "@/components/reports/SlipReport.vue";

export default {
    name: 'ReportsPage',
    computed: {
        fullReport() {
            return fullReport
        }
    },
    components: {
        LabelBuilder,
        ProductReport,
        DeliveryReport,
        CollectionReport,
        FullReport,
        BasePage,
        SlipReport
    },
    data() {
        return {
            collections: [],
            deliveries: [],
            products: [],
            orderedProducts: [],
            page: '',
            reportRun: false,
            reportData: null,
            excludeCollected: false
        }
    },
    async mounted() {
        document.title = "Reports | CarneCloud"
        this.page =  this.$route.params.reportView;
        if(this.page === 'collection') {
            const res = await api.get("/api/reports/collection")
            this.collections = res.data;
        }
        if(this.page === 'delivery') {
            const res = await api.get("/api/reports/delivery")
            this.deliveries = res.data;
        }
        if(this.page === 'products') {
            const res = await api.get("/api/reports/products")
            const orderedRes = await api.get("/api/reports/productCounts")
            this.products = res.data
            this.orderedProducts = orderedRes.data;
        }
    },
    methods: {
        isActive(page) {
            return this.$route.params.reportView === page
        },
        async runReport() {
            let postBody = {};
            postBody.collectionDates = this.$refs.fullReport.selectedCollectionDates;
            postBody.collectionTimes = this.$refs.fullReport.selectedTimes;
            postBody.collectionLocations = this.$refs.fullReport.selectedLocations;
            postBody.deliveryDates = this.$refs.fullReport.selectedDeliveryDates;
            postBody.excludeCollected = this.excludeCollected;
            const res = await api.post("/api/reports/full", postBody)
            console.log(res.data);
            this.reportRun = true;
            this.reportData = res.data;
        },
        productsRowSpan(product) {
            return product.sizes && product.sizes.length > 0 ? product.sizes.length : 1;
        }
    }
}
</script>

<style scoped>
@media print {
    .navbar {
        display: none;
    }
    #reportsMenu {
        display: none;
    }
    .nav-item {
        display: none;
    }
    .avatar {
        display: none !important;
    }
    #basePage {
        display: none;
    }
    #runReportButton {
        display: none;
    }
    .container {
        padding: 10px 0 10px 0 !important;
        max-width: 95% !important;
        width: 95% !important;
    }
}
</style>