<template>
    <BasePage>
        <div class="container">
            <ProductView></ProductView>
        </div>
    </BasePage>
</template>

<script>
import BasePage from '@/components/BasePage.vue';
import ProductView from "@/components/ProductView.vue";  // Adjust the import path if necessary

export default {
    name: 'HomePage',
    components: {
        ProductView,
        BasePage,
    },
};
</script>