<template>
    <BasePage>
        <div class="container">
            <div class="card">
                <div class="card-header">
                    <h2 class="card-title">ChatBot Data</h2>
                    <div class="card-options">
                        <div @click="addingText = true" class="btn btn-sm btn-outline-primary">
                            <IconPlus></IconPlus>
                        </div>
                    </div>
                </div>
                <div v-if="addingText" class="card-body">
                    <textarea v-model="textToAdd" class="form-control mt-4 mb-4"></textarea>
                    <div @click="saveEmbedding" class="btn btn-primary cursor-pointer mb-4">Add</div>
                </div>
                <div class="card-body" v-for="faq in faqs" :key="faq.id">
                    <div class="row">
                        <div class="col">
                            {{ faq.text }}
                        </div>
                        <div class="col-1">
                            <div @click="deleteItem(faq.id)" class="btn btn-sm btn-outline-danger">
                                <IconTrash></IconTrash>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card mt-8">
                <div class="card-header">
                    <h2 class="card-title">Chat</h2>
                </div>
                <div class="card-body">
                    {{ responseText }}
                </div>
                <div class="card-footer">
                    <div class="row">
                        <div class="col-11">
                            <input v-model="chatPrompt" class="form-control mt-2 mb-2">
                        </div>
                        <div class="col">
                            <div @click="sendMessage" class="btn mt-2 mb-2 btn-primary">Send</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </BasePage>
</template>

<script>
document.title = "FAQ | CarneCloud"
import api from '@/services/api'
import BasePage from '@/components/BasePage.vue';  // Adjust the import path if necessary
import {IconTrash, IconPlus} from '@tabler/icons-vue'

export default {
    name: 'ChatBotPage',
    components: {
        BasePage,
        IconPlus,
        IconTrash,
    },
    data() {
        return {
            faqs: [],
            addingText: false,
            textToAdd: "",
            responseText: "",
            chatPrompt: ""
        };
    },
    async mounted() {
        await this.getFaq();
    },
    methods: {
        async getFaq() {
            try {
                const resp = await api.get('https://support-chatbot.larkhall-butchers.workers.dev/notes');
                this.faqs = resp.data.notes

            } catch (e) {
                console.log(e.message)
            }
        },
        async deleteItem(id) {
            await api.delete(`https://support-chatbot.larkhall-butchers.workers.dev/notes/${id}`)
            await this.getFaq();
        },
        async saveEmbedding() {
            await api.post('https://support-chatbot.larkhall-butchers.workers.dev/notes', {text: this.textToAdd})
            await this.getFaq();
            this.addingText = false
            this.textToAdd = ""
        },
        async sendMessage() {
            const res = await api.post('https://support-chatbot.larkhall-butchers.workers.dev/',{question: this.chatPrompt})
            this.responseText = res.data;
        }
    },
};
</script>
