<template>
    <div class="row">
        <div class="col-md-3">
            <div class="card">
                <div class="card-header">
                    Products
                    <div class="card-options">
                        <a class="btb btn-ghost-primary" href="#Products"><IconArrowBigDownLinesFilled></IconArrowBigDownLinesFilled></a>
                    </div>
                </div>
                <div class="card-body">
                    Total: {{ totalProducts }} | Active: {{activeProducts}}
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="card">
                <div class="card-header">
                    Types
                    <div class="card-options">
                        <a class="btb btn-ghost-primary" href="#Types"><IconArrowBigDownLinesFilled></IconArrowBigDownLinesFilled></a>
                    </div>
                </div>
                <div class="card-body">
                    {{ totalTypes }}
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="card">
                <div class="card-header">
                    Total Groups
                    <div class="card-options">
                        <a class="btb btn-ghost-primary" href="#Groups"><IconArrowBigDownLinesFilled></IconArrowBigDownLinesFilled></a>
                    </div>
                </div>
                <div class="card-body">
                    {{ totalGroups }}
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="card">
                <div class="card-header">
                    Total Categories
                    <div class="card-options">
                        <a class="btb btn-ghost-primary" href="#Categories"><IconArrowBigDownLinesFilled></IconArrowBigDownLinesFilled></a>
                    </div>
                </div>
                <div class="card-body">
                    {{ totalCategories }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/services/api';
import {IconArrowBigDownLinesFilled} from '@tabler/icons-vue'
export default {
    components: {
        IconArrowBigDownLinesFilled
    },
    props: {
        categories: {
            type: Array,
            default: null,
        },
        groups: {
            type: Array,
            default: null,
        },
        products: {
            type: Array,
            default: null,
        },
        types: {
            type: Array,
            default: null,
        }
    },
    data() {
        return {
            totalProducts: 0,
            activeProducts: 0,
            totalGroups: 0,
            totalCategories: 0,
            totalTypes: 0,
        };
    },
    async created() {
        if (!this.categories || !this.groups || !this.products) {
            await this.fetchData();
        } else {
            this.updateTotals()
        }
    },
    watch: {
        categories(newVal) {
            if (newVal) {
                this.updateTotals()
            }
        },
        groups(newVal) {
            if (newVal) {
                this.updateTotals()
            }
        },
        products(newVal) {
            if (newVal) {
                this.updateTotals()
            }
        }
    },
    methods: {
        async fetchData() {
            try {
                const [productsRes, groupsRes, categoriesRes] = await Promise.all([
                    api.get('/api/products'),
                    api.get('/api/groups'),
                    api.get('/api/categories'),
                ]);

                this.totalProducts = productsRes.data.length;
                this.activeProducts = productsRes.data.filter(product => product.status === 'active').length;
                this.totalGroups = groupsRes.data.length;
                this.totalCategories = categoriesRes.data.length;
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },
        updateTotals() {
            this.totalProducts = this.products.length;
            this.activeProducts = this.products.filter(product => product.status === 'active').length;
            this.totalGroups = this.groups.length;
            this.totalCategories = this.categories.length;
            this.totalTypes = this.types.length;
        }
    },
};
</script>