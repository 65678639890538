<template>
    <div class="card-header">
        <h2 class="card-title">Delivery Overview</h2>
    </div>
    <div class="card-body">
        <div class="datagrid">
            <div v-for="delivery in deliveries" :key="delivery" class="datagrid-item">
                <div class="datagrid-title">
                    {{delivery.date}}
                </div>
                <div class="datagrid-content">
                    {{delivery.count}}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        deliveries: {
            type: Array,
        }
    }
}
</script>