<template>
    <div class="modal modal-blur fade" :id="modalId" tabindex="-1" role="dialog" aria-modal="true">
        <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
            <div class="modal-content pt-2">
                <div class="modal-status bg-blue"></div>
                <div class="modal-body text-center py-4">
                    <div class="text-secondary">{{ message }}</div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal">{{ submitButton }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "InfoModal",
    props: {
        message: {
            type: String,
            default: ''
        },
        modalId: {
            type: String,
            required: true
        },
        submitButton: {
            type: String,
            default: 'OK'
        },
    }
}
</script>