<template>
    <BasePage>
        <div class="container">
            <div v-for="faq in faqs" :key="faq.faqId" class="card mt-3 mb-3">
                <div class="card-header">
                    <div v-if="editing !== faq.faqId" class="card-title">{{faq.faqTitle}}</div>
                    <input v-if="editing === faq.faqId" type="text" class="form-control mx-6" v-model="editedTitle" />
                    <div class="card-options">
                        <div v-if="editing !== faq.faqId" @click="editing = faq.faqId; editedTitle = faq.faqTitle; editedText = faq.faqText" class="btn-outline-primary btn btn-sm cursor-pointer">
                            <IconEdit></IconEdit>
                        </div>
                        <div v-if="editing === faq.faqId" @click="editing = null" class="btn-outline-danger btn-sm btn cursor-pointer">
                            <IconCircleX></IconCircleX>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <span  v-if="editing !== faq.faqId">{{faq.faqText}}</span>
                    <textarea class="form-control" v-if="editing === faq.faqId" v-model="editedText"></textarea>
                </div>
                <div class="card-footer d-flex" v-if="editing === faq.faqId">
                    <div @click="saveFaq" class="btn btn-primary">Save</div>
                </div>
            </div>
        </div>
    </BasePage>
</template>

<script>
document.title = "FAQ | CarneCloud"
import api from '@/services/api'
import BasePage from '@/components/BasePage.vue';  // Adjust the import path if necessary
import {IconEdit, IconCircleX} from '@tabler/icons-vue'

export default {
    name: 'FAQPage',
    components: {
        BasePage,
        IconEdit,
        IconCircleX
    },
    data() {
        return {
            faqs: [],
            editing: null,
            editedTitle: "",
            editedText: ""
        };
    },
    async mounted() {
            await this.getFaq();
    },
    methods: {
        async saveFaq() {
                await api.put(`/api/faq/${this.editing}`,{faqTitle: this.editedTitle, faqText: this.editedText})
                await this.getFaq()
                this.editing = null;
                this.editedTitle = "";
                this.editedText = "";
        },
        async getFaq() {
            try {
                const resp = await api.get('/api/faq');
                this.faqs = resp.data

            } catch (e) {
                console.log(e.message)
            }
        }
    },
};
</script>
