<template>
    <ul class="pagination">
        <li
                class="page-item"
                :class="{ 'disabled': currentPage === 1 }">
            <a
                    class="page-link"
                    href="#"
                    @click.prevent="changePage(currentPage - 1)">
                <IconChevronLeft></IconChevronLeft>
            </a>
        </li>

        <li
                class="page-item"
                v-for="page in totalPages"
                :key="page"
                :class="{ 'active': page === currentPage }">
            <a
                    class="page-link"
                    href="#"
                    @click.prevent="changePage(page)">
                {{ page }}
            </a>
        </li>

        <li
                class="page-item"
                :class="{ 'disabled': currentPage === totalPages }">
            <a
                    class="page-link"
                    href="#"
                    @click.prevent="changePage(currentPage + 1)">
                <icon-chevron-right></icon-chevron-right>
            </a>
        </li>
    </ul>
</template>

<script>

import {IconChevronLeft, IconChevronRight} from '@tabler/icons-vue'
export default {
    props: {
        totalItems: {
            type: Number,
            required: true
        },
        itemsPerPage: {
            type: Number,
            default: 20
        },
        currentPage: {
            type: Number,
            default: 1
        }
    },
    computed: {
        totalPages() {
            console.log(this.totalItems)
            return Math.ceil(this.totalItems / this.itemsPerPage);
        }
    },
    methods: {
        changePage(page) {
            if (page > 0 && page <= this.totalPages) {
                this.$emit('page-changed', page);
            }
        }
    },
    components: {
        IconChevronLeft,
        IconChevronRight
    }
};
</script>